import React, { useState, useEffect } from 'react';

const VerifySection = () => {
    
    return (
        <div className="verify-section text-center flex items-center h-full">
            <div className="py-8 md:p-0 text-gray-500">
                <h2 className="font-bold text-xl text-gray-500">Thank You for Registering!</h2><br />
                <p>Please wait for the verification process to be completed, which may take up to 2-3 days.</p>
                <p>Once your account is verified, you will receive an email notification with further instructions.</p><br />
                <p>Thank you for your patience</p>
            </div>
        </div>
    );
};

export default VerifySection;