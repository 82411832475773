import React, { useState } from 'react';
import axios from 'axios';
import logoColor from '../../img/logoNameColor.png';
import { FaQuestionCircle } from 'react-icons/fa';
import { LiaTimesSolid } from 'react-icons/lia';

// Assuming you have a Modal component
const Modal = ({ onClose }) => {
  return (
    <div className={`fixed top-0 left-0 bg-gray-600/30 w-[100%] h-[100%] flex items-center justify-center z-50 modal open`}>
      <div className="modal-content bg-white text-gray-500 rounded-md pb-4 w-4/5 md:w-2/5">
          <div className="px-4 py-2 text-right">
            <button className="close-button text-xl" onClick={onClose}><LiaTimesSolid /></button>
            <hr className="" />
          </div>
          <div className="px-4 text-justify">
              <p className="">
              This fee covers the cost of using our platform and ensures continued access to our services.<br />
              This fee supports ongoing platform maintenance, feature enhancements, and ensures a seamless experience for our users.
              </p>
          </div>
      </div>
  </div>
  );
};

const BillingSection = ({ nextStep }) => {
  const [showPaymentButton, setShowPaymentButton] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [transactionId, setTransactionId] = useState(null);
  const [amount, setAmount] = useState(500000); 

  const handlePayment = async () => {
    const options = {
      key: 'rzp_test_i2oOgngv1UW3ZO',
      amount: amount, // Amount in paise (e.g., 2000 paise = Rs 20)
      name: 'MediSeen',
      description: 'Payment for a product or service',
      image: logoColor,
      handler: async function (responseRazor) {
        setTransactionId(responseRazor.razorpay_payment_id);
        try {
          const user = JSON.parse(sessionStorage.getItem('user'));
          const response = await axios.post('https://partners.mediseen.online:3002/api/store-transaction', {
            partnerId: user._id,
            transactionId: responseRazor.razorpay_payment_id,
            amount: amount,
          });
          if (response.status === 201) {
            nextStep();
          }
        } catch (error) {
          console.error(error);
        }
      },
    };
    /* eslint-disable no-undef */
    const rzp = new Razorpay(options);
    /* eslint-enable no-undef */

    rzp.open();

    const razorpayModal = document.querySelector('.razorpay-payment-dialog');
    if (razorpayModal) {
      razorpayModal.style.height = '40%'; // Adjust the height as needed
    }
    setShowPaymentButton(false);
  };

  const openModal = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  return (
    <div className="billing-section text-center">
      <div className="py-8 mt-0 md:mt-10 md:p-0 text-gray-500">
        <p className="font-semibold text-xl ">Thank you for choosing our service!</p>
        <p className="text-sm">Please proceed with the payment to complete the registration.</p>
        <div className="payment-methods text-left mt-8 px-4">
          <h3 className="font-semibold">Annual Fee</h3>
          <hr className="mb-4 mt-1" />
            <div className="flex justify-between text-sm mb-1">
              <div>Sub Total{' '}<FaQuestionCircle className="inline-block text-blue-500 hover:text-blue-600 cursor-pointer" onClick={openModal} /></div>
              <div>₹9,999.00</div>
            </div>
            <div className="flex justify-between text-sm">
              <div>Discount</div>
              <div>₹5,000.00</div>
            </div>
            <hr className="mt-6" />
            <div className="flex justify-between text-lg font-semibold">
              <div>Total</div>
              <div>₹4,999.00</div>
            </div>
            {showPaymentButton && (
              <div className="payment-options text-center mt-12 mb-6">
                <button className="payment-button text-blue-500 bg-white border border-blue-500 hover:bg-blue-500 hover:text-white shadow-sm py-2 w-3/5 font-semibold rounded-sm" onClick={handlePayment}>Pay Now</button>
              </div>
            )}
          </div>
        </div>
        {showModal && <Modal onClose={closeModal} />}
      </div>
  );
};

export default BillingSection;
