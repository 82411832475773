import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { FaEye, FaCircle } from "react-icons/fa";
import { HiMinusSm } from "react-icons/hi";
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const BillRecord = () => {
  const navigate = useNavigate();
  const [user, setUser] = useState(null);
  const [billData, setBillsData] = useState([]);
  const [selectedBillDetails, setSelectedBillDetails] = useState([]);
  const [billDetailModal, setBillDetailsModal] = useState({ open: false, billNumber: null });
  const [billDetailMobileModal, setBillDetailsMobileModal] = useState({ open: false, billNumber: null });
  const [storeName, setStoreName] = useState(''); 
  const [storeAddress, setStoreAddress] = useState('');
  const [customerName, setCustomerName] = useState('');
  const [totalAmount, setTotalAmount] = useState(0.00);
  const [selectedBillNumber, setBillNumber] = useState('');
  const [billDate, setBillDate] = useState('');
  const [additionalDiscount, setAdditionalDiscount] = useState(0.00);
  const [totalBilledAmount, setTotalBilledAmount] = useState(0.00);
  const [totalAmountBeforeDiscount, setTotalAmountBeforeDiscount] = useState(0);
  const [totalDiscount, setTotalDiscount] = useState(0);
  const [isSmallScreen, setIsSmallScreen] = useState(false);

  const [pageLimit, setPageLimit] = useState(10);
  const [page, setPage] = useState(1);
  const [hasMoreData, setHasMoreData] = useState(true);
  const [loading, setLoading] = useState(false);
  const observer = useRef();

  useEffect(() => {
    const userInfo = JSON.parse(sessionStorage.getItem('user'));
    setUser(userInfo);
    const partnerDetails = JSON.parse(sessionStorage.getItem('partnerDetails'));
    setStoreName(partnerDetails.storeName);
    setStoreAddress(partnerDetails.storeLocation);
    if (!userInfo) {
      navigate('/');
    }
  }, [navigate]);

  const handleIntersection = (entries) => {
    const target = entries[0];
    if (target.isIntersecting && !loading && hasMoreData) {
      fetchBillRecords();
    }
  };

  // useEffect(() => {
  //   if (user) {
  //     fetchBillRecords();
  //   }
  // }, [user]);
  useEffect(() => {
    fetchBillRecords(); // Fetch initial data
    // if(billData.length<1)
    // {
    //     setInitialBillData(inventoryData);
    // }
    // Create an observer instance and set up the callback for intersection events
    observer.current = new IntersectionObserver(handleIntersection, {
        root: null,
        rootMargin: '0px',
        threshold: 1.0,
    });
    // Attach the observer to the last item in the list
    const targetNode = document.getElementById('lastItem');
    if (targetNode) {
        observer.current.observe(targetNode);
    }
    // Clean up the observer when the component unmounts
    return () => {
        if (observer.current) {
        observer.current.disconnect();
        }
    };
  }, [billData]);

  useEffect(() => {
    const checkScreenSize = () => {
        setIsSmallScreen(window.innerWidth <= 768);
    };

    // Initial check and add event listener
    checkScreenSize();
    window.addEventListener('resize', checkScreenSize);

    // Cleanup the event listener on component unmount
    return () => {
        window.removeEventListener('resize', checkScreenSize);
    };
  }, []);

    const fetchBillRecords = async () => {
      const user = JSON.parse(sessionStorage.getItem('user'));
      const userIdParam = `userId=${user._id}`;
      const pageParam = `&page=${page}`;
      const limitParam = `&limit=${pageLimit}`;
      try {
          const response = await fetch(`https://partners.mediseen.online:3002/api/billRecords?${userIdParam}${pageParam}${limitParam}`);
          if (response.ok) {
              const data = await response.json();
              // setBillsData(data);
              setBillsData((prevData) => [...prevData, ...data]);
              if (data.length < pageLimit) {
                  setHasMoreData(false);
              }
              setPage((prevPage) => prevPage + 1);
          } else {
              console.error('Failed to fetch vendors data');
          }
      } catch (error) {
          console.error('Error fetching vendors data:', error);
      }
    };

    const fetchBillDetails = async (billNumber, index) => {
        setCustomerName(billData[index].customerName);
        setTotalAmount(billData[index].totalBillAmount);
        setBillNumber(billData[index].billNumber);
        setBillDate(billData[index].billDate);
        setAdditionalDiscount(billData[index].additionalDiscount);
        setTotalBilledAmount((parseFloat(billData[index].totalBillAmount) + parseFloat(billData[index].additionalDiscount)).toFixed(2));

        try {
            const response = await fetch(`https://partners.mediseen.online:3002/api/billData?billNumber=${billNumber}`);
            if (response.ok) {
                const data = await response.json();
                setSelectedBillDetails(data);
                setBillDetailsModal({ open: true, billNumber });
                setBillDetailsMobileModal({ open: true, billNumber });
            } else {
                console.error('Failed to fetch vendors data');
            }
        } catch (error) {
            console.error('Error fetching vendors data:', error);
        }
    };

    const fetchBillDetailsMobile = async (billNumber, index) => {
      setCustomerName(billData[index].customerName);
      setTotalAmount(billData[index].totalBillAmount);
      setBillNumber(billData[index].billNumber);
      setBillDate(billData[index].billDate);
      setAdditionalDiscount(billData[index].additionalDiscount);
      setTotalBilledAmount((parseFloat(billData[index].totalBillAmount) + parseFloat(billData[index].additionalDiscount)).toFixed(2));

      try {
          const response = await fetch(`https://partners.mediseen.online:3002/api/billData?billNumber=${billNumber}`);
          if (response.ok) {
              const data = await response.json();
              setSelectedBillDetails(data);
              const totalAmountBeforeDiscount = data.reduce((total, item) => total + parseFloat(item.itemAmount), 0);
              setTotalAmountBeforeDiscount(totalAmountBeforeDiscount.toFixed(2));
              const totalDiscount = totalAmountBeforeDiscount - billData[index].totalBillAmount;
              setTotalDiscount(totalDiscount.toFixed(2));
              setBillDetailsMobileModal({ open: true, billNumber });
          } else {
              console.error('Failed to fetch vendors data');
          }
      } catch (error) {
          console.error('Error fetching vendors data:', error);
      }
  };

    const handleHideModal = () => {
        setBillDetailsModal({ open: false, billNumber: null });
        setBillDetailsMobileModal({ open: false, billNumber: null });
        setCustomerName('');
        setTotalAmount(0.00);
        setBillNumber('');
        setBillDate('');
        setAdditionalDiscount(0.00);
    };

    const handlePrintBill = () => {
        // Create a temporary container for printing
        const printContainer = document.createElement('div');
    
        // Clone the modalHeader and modalContent elements into the container
        const modalHeader = document.querySelector('.modal-header');
        const clonedHeader = modalHeader.cloneNode(true);
        printContainer.appendChild(clonedHeader);
    
        const modalContent = document.querySelector('.modal-content');
        const clonedContent = modalContent.cloneNode(true);
        printContainer.appendChild(clonedContent);
    
        // Apply print styles to the cloned elements
        const styles = document.createElement('style');
        styles.textContent = `
            /* Your print styles go here */
            @media print {
              body * {
                visibility: hidden;
              }
              html, body {
                height:100%; 
                overflow: hidden;
              }
              .print-content, .print-content * {
                visibility: visible;
              }
              .print-content {
                position: absolute;
                left: 0;
                top: 0;
              }
              .p-4 {
                padding: 1rem;
              }
              .text-gray-500 {
                --tw-text-opacity: 1;
                color: rgb(107 114 128 / var(--tw-text-opacity));
              }
              .flex {
                display: flex;
              }
              .flex-col {
                flex-direction: column;
              }
              .justify-center {
                justify-content: center;
              }
              .font-semibold {
                font-weight: 600;
              }
              .text-2xl {
                  font-size: 1.5rem;
                  line-height: 2rem;
              }
              .text-center {
                  text-align: center;
              }
              .text-xs {
                  font-size: 0.75rem;
                  line-height: 1rem;
              }
              .w-4/5 {
                  width: 80%;
              }
              .storeAddress{

              }
              .mx-auto {
                  margin-left: auto;
                  margin-right: auto;
              }
              .justify-between {
                justify-content: space-between;
              }
              @media (min-width: 768px)
              .md:overflow-y-auto {
                  overflow-y: auto;
              }
              @media (min-width: 768px)
              .md:overflow-auto {
                  overflow: auto;
              }
              @media (min-width: 768px)
              .md:max-h-[28rem] {
                  max-height: 28rem;
              }
              .px-4 {
                  padding-left: 1rem;
                  padding-right: 1rem;
              }
              .overflow-y-scroll {
                  overflow-y: scroll;
              }
              .overflow-hidden {
                  overflow: hidden;
              }
              .gap-4 {
                  gap: 1rem;
              }
              .max-h-[32rem] {
                  max-height: 32rem;
              }
              .pb-4 {
                padding-bottom: 1rem;
              }
              .gap-2 {
                  gap: 0.5rem;
              }
              .justify-end {
                  justify-content: flex-end;
              }
              .border-[1px] {
                border-width: 1px;
              }
              .text-left {
                text-align: left;
              }
              .px-2 {
                  padding-left: 0.5rem;
                  padding-right: 0.5rem;
              }
              .border-collapse {
                border-collapse: collapse;
              }
              .m-0 {
                margin: 0px;
              }
            }
        `;
        printContainer.classList.add('print-content');
        printContainer.appendChild(styles);
        document.body.appendChild(printContainer);
        window.print();
        printContainer.remove();
        handleHideModal();
    };
  
  return (
    <div className="text-sm h-full overflow-y-scroll">
      { !isSmallScreen ? (
        <div className="">
          <div className="flex flex-col md:flex-row h-full text-gray-600">
            <div className="w-full h-full overflow-y-scroll pb-16">
              <div className="p-4 md:py-8 md:px-6 relative">
                <div className="md:flex md:flex-row gap-2">
                  <div className="w-full flex flex-row justify-between">
                    <h2 className="text-xl font-semibold">Billing History</h2>
                  </div>
                </div>
                <div className="w-full mb-[.1rem] mt-4">
                  <div className="flex bg-white shadow-sm rounded-md px-4 p-2 flex-row justify-between mb-2 pt-4 gap-4 font-semibold text-gray-500">
                    <div className="w-1/12">#</div>
                    <div className="w-1/12">Bill No.</div>
                    <div className="w-5/12">Customer Name</div>
                    <div className="w-1/12">Amount</div>
                    <div className="w-1/12">Date</div>
                    <div className="w-1/12">Mode</div>
                    <div className="w-1/12"></div>
                  </div>
                  <div className="flex flex-col gap-4">
                    {billData.map((item, index) => (
                      <div key={item.id} className="flex flex-row justify-between items-baseline bg-white shadow-sm rounded-md px-4 p-2 gap-2 text-sm">
                        <div className="w-1/12">{index+1}</div>
                        <div className="w-1/12">{item.billNumber}</div>
                        <div className="w-5/12">{item.customerName}</div>
                        <div className="w-1/12">{item.totalBillAmount}</div>
                        <div className="w-1/12">{item.billDate}</div>
                        <div className={`w-1/12 ${item.billType === "Offline" ? "text-red-500" : "text-green-500"}`}><span className="flex gap-1 items-center text-xs"><FaCircle className="inline"/>{item.billType}</span></div>
                        <div className="w-1/12 flex justify-center gap-1"><span className="border-2 rounded-md px-2 py-[2px] flex items-center gap-1 text-gray-500 hover:cursor-pointer hover:bg-blue-500 hover:text-white select-none" onClick={() => fetchBillDetails(item.billNumber, index)} ><FaEye className="inline" /> Details</span></div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : 
      (
        <div>
          <h2 className="m-4 mb-3 text-lg font-semibold text-gray-700">Billing History</h2>
            {billData.map((item, index) => (
              <div key={item.id} className="p-2 m-4 border-t-[1px] shadow-md rounded-md flex flex-col gap-1" id={index === billData.length - 1 ? 'lastItem' : null}>
                <div className="flex flex-row justify-between">
                  <div className="font-semibold text-gray-400 text-base">#{item.billNumber}</div>
                  <div className={`w-1/12 flex items-center justify-end text-xs ${item.billType === "Offline" ? "text-red-500" : "text-green-500"}`}><FaCircle /></div>
                </div>
                <div className="flex flex-row justify-between text-gray-500 font-semibold">
                  <div className="">{item.customerName}</div>
                  <div>₹{item.totalBillAmount}</div>
                </div>
                <div className="flex flex-row justify-between">
                  <div className="text-xs">{item.billDate}</div>
                  <div className="flex items-center justify-end text-xs text-blue-500 underline" onClick={() => fetchBillDetailsMobile(item.billNumber, index)}>Details</div>
                </div>
              </div>
            ))}
            {loading && <p>Loading...</p>}
        </div>
      )}
      {billDetailModal.open && (
        <div className={`fixed top-0 left-0 bg-gray-600/30 w-[100%] h-[100%] flex items-center justify-center z-50 modal open`}>
            <div className="bg-white rounded-md w-10/12 md:w-6/12">
                <div className="px-4 flex flex-col gap-4 max-h-[32rem] md:max-h-[28rem] overflow-hidden overflow-y-scroll md:overflow-auto md:overflow-y-auto">
                    <div className="modal-header p-4 text-gray-500">
                        <div className="flex flex-col justify-center">
                            <h4 className="text-center font-semibold m-0 text-2xl">{storeName.toUpperCase()}</h4>
                            <h2 className="storeAddress text-center font-semibold m-0 text-xs w-4/5 mx-auto">{storeAddress}</h2>
                        </div>
                        <div className="flex justify-between">
                            {customerName !== '' && (
                            <div><span className="font-semibold">Cust. Name:</span> {customerName}</div>
                            )}
                            <div><span className="font-semibold">Date:</span> {billDate}</div>
                        </div>
                        <div className="flex justify-between">
                            <div></div>
                            <div><span className="font-semibold">Bill No.:</span> {selectedBillNumber}</div>
                        </div>
                        <hr className="" />
                    </div>
                    <div className="modal-content px-4 flex flex-col max-h-[32rem] md:max-h-[28rem] overflow-hidden overflow-y-scroll md:overflow-auto md:overflow-y-auto">
                        <table className="text-sm border-[1px]">
                            <tr>
                            <th className="text-xs font-semibold text-left px-2">S.NO.</th>
                            <th className="text-xs font-semibold text-left px-2">Name</th>
                            <th className="text-xs font-semibold text-left px-2">Qty</th>
                            <th className="text-xs font-semibold text-left px-2">MRP</th>
                            <th className="text-xs font-semibold text-left px-2">Rate</th>
                            <th className="text-xs font-semibold text-left px-2">Amount</th>
                            <th className="text-xs font-semibold text-left px-2">Discount</th>
                            <th className="text-xs font-semibold text-left px-2">Net Amount</th>
                            </tr>
                            {selectedBillDetails.map((rowData, rowIndex) => (
                            <tr className="text-xs border border-collapse">
                                <td className="px-2">{rowIndex+1}</td>
                                <td className="px-2">{rowData.itemName}</td>
                                <td className="px-2">{rowData.itemQty}</td>
                                <td className="px-2">{rowData.itemPrice}</td>
                                <td className="px-2">{rowData.itemRate}</td>
                                <td className="px-2">{rowData.itemAmount}</td>
                                <td className="px-2">{rowData.itemDiscount}</td>
                                <td className="px-2">{rowData.itemNetAmount}</td>
                            </tr>
                            ))}
                        </table>
                        <div className="text-xs font-medium flex justify-end">
                            <div className="">
                                <table className="border-[1px] border-t-0">
                                    <tr className="border border-collapse border-t-0">
                                    <th className="text-left font-semibold pr-4">Bill Amount:</th>
                                    <td className="text-right">{totalBilledAmount}</td>
                                    </tr>
                                    <tr className="border border-collapse">
                                    <th className="text-left font-semibold pr-4">Additional Discount:</th>
                                    <td className="text-right">{additionalDiscount}</td>
                                    </tr>
                                    <tr className="border border-collapse">
                                    <th className="text-left font-semibold pr-4">Grand Total:</th>
                                    <td className="text-right">{totalAmount}</td>
                                    </tr>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer flex pb-4 justify-end gap-2">
                        <button onClick={handleHideModal} className="text-gray-500 bg-white border px-2 rounded-sm cursor-pointer hover:text-white hover:bg-gray-500 py-1 border-gray-500">Cancel</button>
                        <button onClick={handlePrintBill} className="text-white bg-blue-500 border px-5 py-1 cursor-pointer hover:bg-blue-600 rounded-sm">Print</button>
                    </div>
                </div>
            </div>
        </div>
      )}
      {billDetailMobileModal.open && isSmallScreen && (
        <div className={`fixed top-0 left-0 bg-gray-600/30 w-[100%] h-[100%] flex items-center justify-center z-50 modal open`}>
            <div className="bg-white rounded-md w-11/12 md:w-6/12 text-gray-500">
              <div className="p-4 flex flex-col gap-4 max-h-[32rem] md:max-h-[28rem] overflow-hidden overflow-y-scroll md:overflow-auto md:overflow-y-auto">
                <h3>Bill</h3>
                <div className="flex flex-row bg-white border-t-[1px] shadow-md p-2">
                  <div className="w-5/12">
                    <div className="font-semibold mb-1 text-xl">#{selectedBillNumber}</div>
                    <div className="flex flex-row justify-between text-xs">
                      <div className="font-medium">DATE</div>
                      <div className="font-semibold">-</div>
                      <div>{billDate}</div>
                    </div>
                    <div className="flex flex-row justify-between text-xs">
                      <div className="font-medium">Mode</div>
                      <div className="font-semibold">-</div>
                      <div>Offline</div>
                    </div>
                    <div className="flex flex-row justify-between text-xs">
                      <div className="font-medium">Amount</div>
                      <div className="font-semibold">-</div>
                      <div>₹{totalAmount}</div>
                    </div>
                  </div>
                  <div className="w-7/12 text-right">
                    <div className="font-medium text-lg">Bill To</div>
                    <div className="font-medium text-base">{customerName}</div>
                  </div>
                </div>
                <div className="bg-white border-t-[1px] shadow-md p-2 rounded-md">
                  <div className="flex flex-row text-xs gap-1 text-gray-500 font-bold items-center">
                    <div className="w-[47%] bg-slate-100 py-1">PRODUCT</div>
                    <div className="w-[18%] bg-slate-100 py-1">RATE</div>
                    <div className="w-[12%] bg-slate-100 py-1">QTY</div>
                    <div className="w-[20%] bg-slate-100 py-1">AMOUNT</div>
                  </div>
                  {selectedBillDetails.map((rowData, rowIndex) => (
                    <div className="flex flex-row gap-2 text-gray-500 items-center">
                      <input type="text" className="w-[47%] bg-white shadow-sm py-1" value={rowData.itemName} disabled readOnly />
                      <div className="w-[18%] bg-white shadow-sm py-1 text-right">{rowData.itemRate}</div>
                      <div className="w-[12%] bg-white shadow-sm py-1 text-right">{rowData.itemQty}</div>
                      <div className="w-[20%] bg-white shadow-sm py-1 text-right">{rowData.itemAmount}</div>
                    </div>
                  ))}
                </div>
                <div className="flex flex-col text-right mx-4 text-gray-500">
                  <div className="flex flex-row items-center gap-3">
                    <div className="w-8/12 text-sm font-semibold">Bill Amount</div>
                    <div className="w-1/12 text-sm"><HiMinusSm /></div>
                    <div className="w-3/12 text-sm font-semibold">₹ {totalAmountBeforeDiscount}</div>
                  </div>
                  <div className="flex flex-row items-center gap-3">
                    <div className="w-8/12 text-sm font-semibold">Total Discount</div>
                    <div className="w-1/12 text-sm"><HiMinusSm /></div>
                    <div className="w-3/12 text-sm font-semibold">₹ {totalDiscount}</div>
                  </div>
                  <div className="flex flex-row items-center gap-3">
                    <div className="w-8/12 text-sm font-semibold">Grand Total</div>
                    <div className="w-1/12 text-sm"><HiMinusSm /></div>
                    <div className="w-3/12 text-sm font-semibold">₹ {totalAmount}</div>
                  </div>
                </div>
                <div className="modal-footer flex justify-end gap-4">
                    <button onClick={handleHideModal} className="text-gray-500 bg-white border w-4/12 rounded-sm cursor-pointer hover:text-white hover:bg-gray-500 py-1 border-gray-500">Cancel</button>
                    <button onClick={handlePrintBill} className="text-white bg-blue-500 border w-4/12 py-1 cursor-pointer hover:bg-blue-600 rounded-sm">Print</button>
                </div>
              </div>
            </div>
        </div>
      )}
      <ToastContainer />
      <div className={`fixed hidden top-0 left-0 bg-gray-600/30 w-[100%] h-[100%] items-center justify-center z-50 modal open`}>
        <div className="bg-white rounded-md w-10/12 md:w-6/12">
          <div className="modal-header p-4 text-gray-500">
            <div className="flex flex-col justify-center">
              <h4 className="text-center font-semibold m-0 text-2xl">{storeName.toUpperCase()}</h4>
              <h2 className="text-center font-semibold m-0 text-xs md:w-4/5 mx-auto">{storeAddress}</h2>
            </div>
            <div className="flex justify-between">
              {customerName !== '' && (
              <div><span className="font-semibold">Cust. Name:</span> {customerName}</div>
              )}
              <div><span className="font-semibold">Date:</span> {billDate}</div>
            </div>
            <div className="flex justify-between">
              <div></div>
              <div><span className="font-semibold">Bill No.:</span> #{selectedBillNumber}</div>
            </div>
            <hr className="" />
          </div>
          <div className="modal-content px-4 flex flex-col max-h-[32rem] md:max-h-[28rem] overflow-hidden overflow-y-scroll md:overflow-auto md:overflow-y-auto">
            <table className="text-sm border-[1px]">
              <tr>
                <th className="text-xs font-semibold text-left px-2">S.NO.</th>
                <th className="text-xs font-semibold text-left px-2">Name</th>
                <th className="text-xs font-semibold text-left px-2">Qty</th>
                <th className="text-xs font-semibold text-left px-2">MRP</th>
                <th className="text-xs font-semibold text-left px-2">Rate</th>
                <th className="text-xs font-semibold text-left px-2">Amount</th>
                <th className="text-xs font-semibold text-left px-2">Discount</th>
                <th className="text-xs font-semibold text-left px-2">Net Amount</th>
              </tr>
              {selectedBillDetails.map((rowData, rowIndex) => (
                <tr className="text-xs border border-collapse">
                  <td className="px-2">{rowIndex+1}</td>
                  <td className="px-2">{rowData.itemName}</td>
                  <td className="px-2">{rowData.itemQty}</td>
                  <td className="px-2">{rowData.itemPrice}</td>
                  <td className="px-2">{rowData.itemRate}</td>
                  <td className="px-2">{rowData.itemAmount}</td>
                  <td className="px-2">{rowData.itemDiscount}</td>
                  <td className="px-2">{rowData.itemNetAmount}</td>
                </tr>
            ))}
            </table>
            <div className="text-xs font-medium flex justify-end">
              <div className="">
                <table className="border-[1px] border-t-0">
                  <tr className="border border-collapse border-t-0">
                    <th className="text-left font-semibold pr-4">Bill Amount:</th>
                    <td className="text-right">₹ {totalAmountBeforeDiscount}</td>
                  </tr>
                  <tr className="border border-collapse">
                    <th className="text-left font-semibold pr-4">Total Discount:</th>
                    <td className="text-right">₹ {totalDiscount}</td>
                  </tr>
                  <tr className="border border-collapse">
                    <th className="text-left font-semibold pr-4">Grand Total:</th>
                    <td className="text-right">₹ {totalAmount}</td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BillRecord;
