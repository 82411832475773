import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom'; 
import axios from 'axios';
import HeaderBg from '../img/bg_2.jpg';
import logoWhite from '../img/logoNameWhite.png';
import logoColor from '../img/logoNameColor.png';
import listingIcon from '../img/registerpageicons/listing.png';
import signUpIcon from '../img/registerpageicons/signUp.png';
import orderIcon from '../img/registerpageicons/order.png';
import customer1Img from '../img/happycustomers/customer1.jpg';
// import customer2Img from '../img/happycustomers/customer2.jpg';
// import customer3Img from '../img/happycustomers/customer3.png';
import { FaBars, FaTimes, FaQuoteLeft, FaQuoteRight, FaUserCircle, FaFacebookF, FaTwitter, FaInstagram } from 'react-icons/fa';
import {MdArrowBackIosNew, MdArrowDropDown} from 'react-icons/md';
import { BsCheck, BsCircleFill, BsEye, BsEyeSlash, BsX } from 'react-icons/bs';
import OtpInput from 'react-otp-input';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { toast, ToastContainer } from 'react-toastify';

const Registration = () => {
    const navigate = useNavigate();

    // Declare and initialize state variables
    const [fullName, setFullName] = useState('');
    const [email, setEmail] = useState('');
    const [loginEmail, setLoginEmail] = useState('');
    const [isChecked, setIsChecked] = useState(false);
    const [fullNameValid, setFullNameValid] = useState(true);
    const [emailValid, setEmailValid] = useState(true);
    const [loginEmailValid, setLoginEmailValid] = useState(true);
    const [emailExistError, setEmailExistError] = useState(null);
    const [loginEmailExistError, setLoginEmailExistError] = useState(null);
    const [otpInvalidError, setOTPInvalidError] = useState(null);
    const [otp, setOtp] = useState('');
    const [otpExpiration, setOtpExpiration] = useState(null); // Store OTP expiration time
    const [step, setStep] = useState(1);
    const [loginStep, setLoginStep] = useState(1);
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [loginPassword,setLoginPassword] = useState('');
    const [isPasswordMismatch,setPasswordMismatch] = useState(false);
    const [remainingTime, setRemainingTime] = useState(30); // Initial remaining time in seconds
    const [timerVisible, setTimerVisible] = useState(true);
    const [minCharState,setMinCharState] = useState(1);
    const [oneUpCaseState,setOneUpCaseState] = useState(1);
    const [oneNumState,setOneNumState] = useState(1);
    const [oneSpecSymbState,setOneSpecSymbState] = useState(1);
    const [showPassword, setShowPassword] = useState(false);
    const [showCnfPassword, setShowCnfPassword] = useState(false);
    const [showLoginPassword, setShowLoginPassword] = useState(false);
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [showUserDropdown, setShowUserDropdown] = useState(false);
    const [userName, setUserName ] = useState('');

    const togglePasswordVisibility = () => {
      setShowPassword(!showPassword);
    };
    const toggleCnfPasswordVisibility = () => {
        setShowCnfPassword(!showCnfPassword);
      };
    const toggleLoginPasswordVisibility = () => {
        setShowLoginPassword(!showLoginPassword);
    };
    const inputType = showPassword ? 'text' : 'password';
    const inputTypeCnf = showCnfPassword ? 'text' : 'password';
    const inputTypeLogin = showLoginPassword ? 'text' : 'password';

    useEffect(() => {
        // Check if OTP has expired
        if (otpExpiration && new Date() > new Date(otpExpiration)) {
            // OTP has expired, reset the step and OTP
            setStep(1);
            setLoginStep(1);
            setOtp('');
            setOtpExpiration(null);
        }
    }, [otpExpiration]);
    const handleLogout = () => {
        sessionStorage.removeItem('user');
        // navigate('/registration');
        window.location.reload();
    };
    useEffect(() => {
        const storedUser = sessionStorage.getItem('user');
        if (storedUser !== "undefined") {
            const user = JSON.parse(storedUser);

            if (user) {
                setIsLoggedIn(true);
                setUserName(user.fullName);
            }
        }
    }, []);
    useEffect(() => {
        let intervalId;
        // Function to decrement the remaining time and hide the timer when it reaches 0
        const updateTimer = () => {
            setRemainingTime((prevTime) => {
                if (prevTime === 1) {
                    clearInterval(intervalId);
                    setTimerVisible(false); // Hide the timer
                }
                return prevTime - 1;
            });
        };
        // Start the timer when the component mounts
        if (timerVisible) 
        {
            intervalId = setInterval(updateTimer, 1000);
        }
        // Clear the interval when the component unmounts
        return () => {
            clearInterval(intervalId);
        };
    }, [timerVisible]);

    const handleResend = async () => {
        // Add logic to resend the verification code here
        // You can also reset the timer if needed
        setOTPInvalidError(null);
        setOtp('');
        try {
            // Send user details to the backend
            const response = await axios.post('https://partners.mediseen.online:3002/api/resend-otp', {
                email,
            });
            if (response.status === 201) {
                // Store OTP expiration time (5 minutes from now)
                const expirationTime = new Date();
                expirationTime.setMinutes(expirationTime.getMinutes() + 5);
                setOtpExpiration(expirationTime);
                setRemainingTime(15); // Reset the timer to 30 seconds
                setTimerVisible(true); // Show the timer again
            }
        } catch (error) {
            console.error(error);
        }
    };

    const handleLogin = async () => {
        setLoginEmailExistError(null);
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailPattern.test(loginEmail)) {
            setLoginEmailValid(false);
            return;
        }
        try {
            // Send user details to the backend
            const response = await axios.post('https://partners.mediseen.online:3002/api/login', {
                loginEmail,loginPassword
            });
            if (response.status === 200 && response.data.message === 'Email not registered') 
            {
                setLoginEmailExistError(displayErrorWithLoginOptionMain(1));
            }
            else if (response.status === 200 && response.data.message === 'Login successful') {
                setIsLoggedIn(true);
                setUserName(response.data.user.fullName);
                sessionStorage.setItem('user', JSON.stringify(response.data.user));
                sessionStorage.setItem('currentStep', JSON.stringify(response.data.currentStep));
                sessionStorage.setItem('partnerDetails', JSON.stringify(response.data.partnerDetails));
                sessionStorage.setItem('appliedForPartner', JSON.stringify(response.data.appliedForPartner));
                setIsLoginModalOpen(false);
                if(response.data.user.status === "Registered" || response.data.user.status === "Pending Approval")
                {
                    if(response.data.appliedForPartner){
                        navigate('/partner-register');
                    }else{
                        openNotifyModal();
                        // navigate('/registration');
                    }
                }else if(response.data.user.status === "Completed")
                {
                    navigate('/dashboard');
                }
            } else if(response.status === 201)
            {
                // 2FA code sent
                // setLoginStep(2);
            }else{
                setLoginEmailExistError(displayErrorWithLoginOptionMain(2));
            }
        } catch (error) {
            console.error(error);
        }
    };
    
    const checkPassword = (value) => {
        setMinCharState(2);
        setOneUpCaseState(2);
        setOneNumState(2);
        setOneSpecSymbState(2);
        // Check for uppercase letter
        const uppercaseRegex = /[A-Z]/;
        if(uppercaseRegex.test(value)){
            setOneUpCaseState(3);
        }
        // Check for special symbol (you can define your own set of special symbols)
        const specialSymbolRegex = /[!@#$%^&*()_+{}[\]:;<>,.?~\\/-]/;
        if(specialSymbolRegex.test(value)){
            setOneSpecSymbState(3);
        }
        // Check for number
        const numberRegex = /[0-9]/;
        if(numberRegex.test(value)){
            setOneNumState(3);
        }
        // Check for length (at least 8 characters)
        if(value.length >= 8){
            setMinCharState(3);
        }
      };
    const handlePasswordChange = (e) => {
        const value = e.target.value;
        var newValue = value.replace(/\s/g, '');
        setPassword(newValue);
        checkPassword(newValue);
    };
    const handleLoginPasswordChange = (e) => {
        const value = e.target.value;
        var newValue = value.replace(/\s/g, '');
        setLoginPassword(newValue);
        // checkPassword(newValue);
    }
    const handleLoginEmailChange = (e) => {
        const value = e.target.value;
        var newValue = value.replace(/\s/g, '');
        setLoginEmail(newValue);
        // checkPassword(newValue);
    }
    const handleCheckboxChange = (e) => {
        setIsChecked(e.target.checked);
    };    
    const handleFullNameFocus = () => {
    // Reset fullName input border color when it gains focus
    setFullNameValid(true);
    };

    const handleEmailFocus = () => {
    // Reset email input border color when it gains focus
    setEmailValid(true);
    };
    const handleLoginEmailFocus = () => {
        // Reset email input border color when it gains focus
        setLoginEmailValid(true);
    };
    

    const buttonBackgroundColor = isChecked ? 'bg-blue-500' : 'bg-gray-400';
    const buttonHoverBackgroundColor = isChecked ? 'bg-blue-600' : 'bg-gray-400';
    const fullNameBorderColor = fullNameValid ? 'shadow-none border-gray-300' : 'shadow-bs border-red-500';
    const emailBorderColor = emailValid ? 'shadow-none border-gray-300' : 'shadow-bs border-red-500';
    const loginEmailBorderColor = loginEmailValid ? 'shadow-none border-gray-300' : 'shadow-bs border-red-500';
    
    const headerStyle = {
        backgroundImage: `url(${HeaderBg})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
    };
    const [isRegistrationModalOpen, setIsRegistrationModalOpen] = useState(false);
    const openRegistrationModal = () => {
        setOTPInvalidError(null);
        setConfirmPassword('');
        setPassword('');
        toggleMobileMenu();
        setIsRegistrationModalOpen(true);
        setIsLoginModalOpen(false);
    };

    const checkAccountStatus = () => {
        const user = JSON.parse(sessionStorage.getItem('user'));
        if (!user) {
            setIsRegistrationModalOpen(true);
            setIsLoginModalOpen(false);
        }else
        {
            if(user.status==="Completed"){
                navigate('/dashboard');
            }else{
                navigate('/partner-register');
            }
        }
    };
    const checkStore = () => {
        const user = JSON.parse(sessionStorage.getItem('user'));
        if(!user){
            openLoginModal();
            return;
        }
        const appliedForPartner = JSON.parse(sessionStorage.getItem('appliedForPartner'));
        if(user.status==="Completed"){
            navigate('/dashboard');
        }else if(user.staus==="Pending Approval" || appliedForPartner){
            navigate('/partner-register');
        }else{
            openNotifyModal();
        }
    };

    const closeRegistrationModal = () => {
        setIsRegistrationModalOpen(false);
    };
    const [isLoginModalOpen, setIsLoginModalOpen] = useState(false);
    const openLoginModal = () => {
        setOTPInvalidError(null);
        setConfirmPassword('');
        setPassword('');
        setMobileMenuOpen(false);
        setIsLoginModalOpen(true);
        setIsRegistrationModalOpen(false);
    };

    const closeLoginModal = () => {
        setIsLoginModalOpen(false);
    };

    const [isPopUpOpen, setIsPopUpOpen] = useState(false);
    const openPopUP = () => {
        setIsPopUpOpen(true);
    }
    const hidePopup = () => {
        setIsPopUpOpen(false);
    }
    const [notifyModal, setNotifyModal] = useState(false);
    const openNotifyModal = () => {
        setNotifyModal(true);
    }
    const hideNotifyModal = () => {
        setNotifyModal(false);
    }

    const handleCreateAccount = async () => {
        setEmailExistError(null);
        setOTPInvalidError(null);
        if (!isChecked) {
            return;
        }
        // Check if fullName contains alphabets or spaces (not just spaces)
        const fullNamePattern = /^[A-Za-z\s]+$/;
        if (!fullNamePattern.test(fullName.trim())) {
            setFullNameValid(false);
            return;
        }
        // Check if email is a valid email address
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailPattern.test(email)) {
            setEmailValid(false);
            return;
        }
        // create account
        try {
            // Send user details to the backend
            const response = await axios.post('https://partners.mediseen.online:3002/api/register', {
                fullName,
                email,
            });
            if (response.status === 201) {
                // Registration successful, move to the next step (Enter OTP)
                setStep(2);
                // Store OTP expiration time (5 minutes from now)
                const expirationTime = new Date();
                expirationTime.setMinutes(expirationTime.getMinutes() + 5);
                setOtpExpiration(expirationTime);
                setRemainingTime(15);
                setTimerVisible(true);
            } else if(response.status === 200){
                setEmailExistError(displayErrorWithLoginOption());
            }
            else {
                // Handle registration failure
                //console.error('Registration failed');
            }
        } catch (error) {
            console.error(error);
        }
    };
    const displayErrorWithLoginOption = () => {
        return (
          <span className="text-red-500 text-xs font-medium">
            Email Already Registred! <span className="text-blue-500 font-normal underline cursor-pointer" onClick={openLoginModal}>Try Login</span>
          </span>
        );
    };
    const displayErrorWithLoginOptionMain = (mode) => {
        if(mode===2)
        {
          return (
            <span className="text-red-500 text-xs font-medium">
              Email or Password is incorrect! <span className="text-blue-500 font-normal underline cursor-pointer" onClick={(forgotPassword)}>Forgot Password?</span>
            </span>
          );
        }
        else{
          return (
            <span className="text-red-500 text-xs font-medium">
              Email does not exist! <span className="text-blue-500 font-normal underline cursor-pointer" onClick={openRegistrationModal}>Sign Up?</span>
            </span>
          );
        }
    };
    const forgotPassword = async () => {
        try {
            // Send user details to the backend
            const response = await axios.post('https://partners.mediseen.online:3002/api/forgot-password', {
                email: loginEmail
            });
            if (response.status === 200 && response.data.message === 'User not registered') 
            {
              //
            }
            else if (response.status === 200 && response.data.message === 'OTP send successful') 
            {
              // change the screen for otp
              setLoginStep(2);
              const expirationTime = new Date();
              expirationTime.setMinutes(expirationTime.getMinutes() + 5);
              setOtpExpiration(expirationTime);
              setRemainingTime(15); // Reset the timer to 30 seconds
              setTimerVisible(true); // Show the timer again
            } else
            {
              // otp failed
            }
        } catch (error) {
            console.error(error);
        }
      };
    const displayErrorOTPVerify = () => {
        return (
          <div className="w-full text-red-500 text-xs text-center font-medium">
            Invalid Code!
          </div>
        );
    };
    const handleVerifyOTP = async () => {
        try {
            // Send OTP to the backend for verification
            const response = await axios.post('https://partners.mediseen.online:3002/api/verify-otp', {
                email,
                otp,
            });

            if (response.status === 200) {
                // OTP verification successful
                setStep(3);
            } else {
                // Handle OTP verification failure
                setOTPInvalidError(displayErrorOTPVerify());
            }
        } catch (error) {
            console.error(error);
        }
    };
    const handleVerifyLoginOTP = async () => {
        try {
            // Send OTP to the backend for verification
            const response = await axios.post('https://partners.mediseen.online:3002/api/verify-otp', {
                email: loginEmail,
                otp,
            });

            if (response.status === 200) {
                // OTP verification successful
                setLoginStep(3);
            } else {
                // Handle OTP verification failure
                setOTPInvalidError(displayErrorOTPVerify());
            }
        } catch (error) {
            console.error(error);
        }
    };

    const changeState = async () => {
        setStep(1);
        setLoginStep(1);
    }

    const handleSetPassword = async () => {
        if(minCharState !== 3 || oneUpCaseState !==3 || oneNumState !== 3 || oneSpecSymbState !== 3)
        {
            return;
        }
        if(password !== confirmPassword){
            setPasswordMismatch(true);
            return;
        }
        try {
            // Send password and email to the backend to set the password
            const response = await axios.post('https://partners.mediseen.online:3002/api/set-password', {
                email,
                password,
            });

            if (response.status === 200) {
                // Password set successfully
                // const message = "Congratulations! Your account has been created successfully.<br />Please sign in to continue";
                openPopUP();
                setIsLoginModalOpen(false);
                setIsRegistrationModalOpen(false);
                //navigate('/');
                // You can optionally navigate the user to a login page here
            } else {
                // Handle password setting failure
                // console.error('Password setting failed');
            }
        } catch (error) {
            console.error(error);
        }
    };
    const handleSetLoginPassword = async () => {
        if(minCharState !== 3 || oneUpCaseState !==3 || oneNumState !== 3 || oneSpecSymbState !== 3)
        {
            return;
        }
        if(password !== confirmPassword){
            setPasswordMismatch(true);
            return;
        }
        try {
            // Send password and email to the backend to set the password
            const response = await axios.post('https://partners.mediseen.online:3002/api/set-password', {
                email: loginEmail,
                password,
            });

            if (response.status === 200) {
                // Password set successfully
                // const message = "Congratulations! Your account has been created successfully.<br />Please sign in to continue";
                toast.success('Password updated successfully!', { position: 'top-right' });
                setIsLoginModalOpen(false);
                setIsRegistrationModalOpen(false);
                //navigate('/');
                // You can optionally navigate the user to a login page here
            } else {
                // Handle password setting failure
                // console.error('Password setting failed');
            }
        } catch (error) {
            console.error(error);
        }
    };
    const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);

    const toggleMobileMenu = () => {
        setMobileMenuOpen(!isMobileMenuOpen);
    };

    const features = [
        {
          title: 'Expand Your Customer Base',
          description: 'Reach a broader audience and attract new customers through our user-friendly platform.',
        },
        {
          title: 'Increase Revenue',
          description: 'Tap into new revenue streams by selling your medicines online with ease.',
        },
        {
          title: 'Inventory Management Made Simple',
          description: 'Benefit from our cutting-edge inventory management solution to optimize stock levels and reduce waste.',
        },
        // {
        //     title: 'Tech-Driven Efficiency',
        //     description: 'Leverage our advanced technology to streamline processes, saving you time and resources.',
        // },
        // {
        //     title: 'Marketing Support',
        //     description: 'Enjoy marketing and promotional support to enhance your visibility and attract more customers',
        // },
    ];

    const steps = [
        {
          title: 'Sign Up',
          description: 'Complete a simple registration process to become a partner',
          img: signUpIcon,
          bgColor: 'bg-blue-100',
          num: 1,
        },
        {
          title: 'List Medicines',
          description: 'Easily list your available medicines with detailed information',
          img: listingIcon,
          bgColor: 'bg-green-100',
          num: 2,
        },
        {
          title: 'Receive Orders',
          description: 'Seamlessly handle online orders from customers',
          img: orderIcon,
          bgColor: 'bg-yellow-100',
          num: 3,
        },
    ];

    const comments = [
        {
          name: 'Kunal Yadav',
          desc: 'Since partnering with MediSeen, our sales have increased by 30%, and the inventory management system has transformed our operations.',
          img: customer1Img,
        },
        {
            name: 'Kunal Yadav',
            desc: "The platform's user-friendly interface makes it easy for us to manage our listings and orders. It's a game-changer for our business.",
            img: customer1Img,
        },
        {
            name: 'Kunal Yadav',
            desc: 'Since partnering with MediSeen, our sales have increased by 30%, and the inventory management system has transformed our operations.',
            img: customer1Img,
        },
    ];

    const faqs = [
        {
          question: 'How do I sign up as a partner?',
          answer: 'To sign up as a partner, visit our registration page and complete the simple sign-up process. Once your registration is approved, you can start listing your medicines and receiving orders.'
        },
        {
          question: 'What services does MediSeen provide for partners?',
          answer: 'MediSeen offers a platform for medical stores to list their available medicines, manage inventory, and receive online orders. Our user-friendly interface makes it easy for partners to streamline their operations.'
        },
        {
          question: 'How can I manage my inventory on MediSeen?',
          answer: 'Our platform provides an easy-to-use inventory management system. Partners can easily update their available medicines, add new products, and track stock levels.'
        },
        // Add more FAQ items as needed
    ];

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 5000,
      };

    const [selectedQuestion, setSelectedQuestion] = useState(null);

    const toggleAnswer = (index) => {
        setSelectedQuestion(selectedQuestion === index ? null : index);
    };
    
    return (
        <div className="flex flex-col min-h-screen">
            <div style={headerStyle}>
                <header className="text-white h-16 flex items-center justify-between px-4 md:px-8 mb-8">
                    <div className="flex items-center">
                        <h1 className="text-2xl font-semibold mr-2">
                        <img src={logoWhite} alt="MediSeen" className="w-32" />
                        </h1>
                        <p className="text-sm text-gray-300 relative top-1 hidden md:block">Partners</p>
                    </div>
                    {!isLoggedIn ? (
                        <>
                            {/* Mobile menu button */}
                            <button className="md:hidden" onClick={toggleMobileMenu}>
                                <FaBars className="text-white text-2xl" />
                            </button>

                            {/* Mobile menu */}
                            {isMobileMenuOpen && (
                                <div className="md:hidden fixed top-0 left-0 bg-white w-screen h-screen flex items-center justify-center z-50">
                                    <div className="text-gray-500 flex flex-col gap-4">
                                        {/* Close icon */}
                                        <button className="absolute top-4 right-4" onClick={toggleMobileMenu}>
                                            <FaTimes className="text-gray-500 text-2xl" />
                                        </button>
                                        {/* <p className="text-sm text-gray-400">Partners</p> */}
                                        <button className="text-lg font-semibold" onClick={openLoginModal}>
                                            Login
                                        </button>
                                        <button className="text-lg font-semibold" onClick={openRegistrationModal}>
                                            Create Account
                                        </button>
                                    </div>
                                </div>
                            )}

                            {/* Desktop menu */}
                            <div className="hidden md:flex items-center space-x-4">
                                <button className="text-sm bg-gray-500 py-2 px-4 shadow-sm rounded-md hover:bg-gray-600 text-white" onClick={openLoginModal}>
                                    Login
                                </button>
                                <button className="text-sm bg-blue-600 py-2 px-4 shadow-sm rounded-md hover:bg-blue-700 text-white" onClick={openRegistrationModal}>
                                    Create Account
                                </button>
                            </div>
                        </>
                    ) : (
                        <div className="font-semibold text-sm md:text-base">
                            <FaUserCircle className="inline-block text-3xl md:text-4xl" /><span onClick={() => setShowUserDropdown(!showUserDropdown)}>{' '}Hello, {userName}{''}</span>
                            {/* <MdArrowDropDown className="inline-block text-2xl" /> */}
                            <div className="relative group inline-block">
                                {showUserDropdown && (
                                    <div className="absolute right-0 mt-6 w-auto bg-white text-blue-500 border rounded-lg shadow-lg">
                                        {/* Add content for the user dropdown here, e.g., a Logout button */}
                                        <button onClick={handleLogout} className="block w-full py-2 px-4 text-base text-left rounded-lg hover:bg-gray-100">
                                            Logout
                                        </button>
                                    </div>
                                )}
                                <MdArrowDropDown
                                    className="inline-block text-2xl cursor-pointer"
                                    onClick={() => setShowUserDropdown(!showUserDropdown)}
                                />
                            </div>
                        </div>
                    )}
                </header>
                <div className="md:w-3/5 w-4/5 mx-auto mt-12 md:mt-4 mb-16 text-white">
                    <p className="text-xl md:text-4xl md:text-left text-center">Partner with Mediseen<br/>at 0% commission for the 1st month!</p>
                    <p className="mb-4 mt-6 md:text-left text-center"><span className="font-semibold">Ready to advance?</span> Join forces with MediSeen for opportunities and streamlined operations.</p>
                    <div className="md:flex items-center mt-4">
                        <button type="submit" className="w-full bg-blue-500 text-white p-2 rounded-md hover:bg-blue-600 transition duration-300 m-1" onClick={checkAccountStatus}>Register your store</button>
                        <button type="submit" className="w-full bg-white text-gray-800 p-2 rounded-md hover:text-blue-500 transition duration-300 m-1" onClick={checkStore}>Check existing store</button>
                    </div>
                    <p className="text-sm md:text-left text-center">Need help? Please email us at partneronboarding@mediseen.online</p>
                </div>
            </div>
            <div className="bg-slate-100 pb-8">
                <div className="md:w-3/5 w-4/5 mx-auto bg-white mt-[-20px] rounded p-4 pt-8 shadow-md">
                    <p className="text-center text-xl md:text-3xl">Get started with online ordering</p>
                    <p className="text-center text-gray-500 pt-2">Please keep the documents ready for a smooth signup</p>
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-8 my-8 md:mx-6">
                        <div className="flex items-center">
                            <div className="w-5 h-5 bg-blue-500 rounded-full flex items-center justify-center mr-2">
                                <svg className="w-3 h-3 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"/>
                                </svg>
                            </div>
                            <span className="text-sm">Drug License Copy</span>
                        </div>
                        <div className="flex items-center">
                            <div className="w-5 h-5 bg-blue-500 rounded-full flex items-center justify-center mr-2">
                                <svg className="w-3 h-3 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"/>
                                </svg>
                            </div>
                            <span className="text-sm">PAN Card Copy</span>
                        </div>
                        <div className="flex items-center">
                            <div className="w-5 h-5 bg-blue-500 rounded-full flex items-center justify-center mr-2">
                                <svg className="w-3 h-3 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"/>
                                </svg>
                            </div>
                            <span className="text-sm">Regular GSTIN (if applicable)</span>
                        </div>
                        <div className="flex items-center">
                            <div className="w-5 h-5 bg-blue-500 rounded-full flex items-center justify-center mr-2">
                                <svg className="w-3 h-3 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"/>
                                </svg>
                            </div>
                            <span className="text-sm">Bank Account Details</span>
                        </div>
                    </div>
                </div>
            </div>
            <div className="">
                <div className="md:w-4/6 w-4/5 mx-auto my-12">
                    <h2 className="text-3xl font-semibold text-center mb-8">
                        Why Partner with Us?
                    </h2>
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
                    {features.map((feature, index) => (
                        <div key={index} className="p-6 bg-white rounded-lg shadow-md transform transition duration-300 hover:scale-105">
                        <h3 className="text-xl font-semibold mb-4">{feature.title}</h3>
                        <p className="text-gray-700">{feature.description}</p>
                        </div>
                    ))}
                    </div>
                </div>
            </div>
            <div className="bg-slate-100">
                <div className="md:w-4/6 w-4/5 mx-auto my-12">
                    <h2 className="text-3xl font-semibold text-center mb-8">
                        How It Works?
                    </h2>
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
                    {steps.map((step, index) => (
                        <div key={index} className="p-6 bg-white text-gray-800 rounded-lg text-center shadow-md transform transition duration-300 hover:scale-105">
                            <div className={`mx-auto mb-4 p-8 rounded-full ${step.bgColor}`} style={{ width: '120px', height: '120px' }}>
                                <img src={step.img} alt={step.title} className="block w-full h-full object-cover" />
                            </div>
                            <h3 className="text-xl font-semibold mb-4">Step {step.num}</h3>
                            <h4 className="font-semibold mb-2">{step.title}</h4>
                            <p className="text-gray-500 text-sm">{step.description}</p>
                        </div>
                    ))}
                    </div>
                </div>
            </div>
            <div className="bg-white">
                <div className="md:w-4/6 w-4/5 relative text-gray-700 mx-auto mt-12 mb-20">
                    <FaQuoteLeft className="absolute text-8xl top-6 left-6 left text-gray-100" />
                    <FaQuoteRight className="absolute text-8xl bottom-0 right-6 left text-gray-100" />
                    <h2 className="relative z-10 text-3xl font-semibold text-center mb-8">
                        Partner Success Stories
                    </h2>
                    <Slider {...settings}>
                    {comments.map((comment, index) => (
                        <div key={index} className="slick-slide" style={{ height: '300px' }}>
                            <div className="flex flex-col md:flex-row p-6 z-50 justify-center items-center md:items-start md:bg-transparent md:shadow-none  text-gray-800 rounded-lg text-center h-full">
                                <div className="w-[110px] h-[110px] mb-4 md:mb-2 md:rounded-md shadow-md rounded-full md:mr-4 overflow-hidden">
                                    <img src={comment.img} alt={comment.name} className="block w-full h-full object-cover" />
                                </div>
                                <div className="md:w-2/3 md:text-left">
                                    <p className="text-gray-500 text-sm">{comment.desc}</p>
                                    <div className="text-gray-500 font-semibold pt-2 text-right text-sm z-50">-{comment.name}</div>
                                </div>
                            </div>
                        </div>
                    ))}
                    </Slider>
                </div>
            </div>
            <div className="bg-slate-100">
                <div className="md:w-4/6 w-4/5 mx-auto my-16 bg-blue-950 text-white p-8 rounded-md">
                    <h2 className="md:text-3xl text-lg font-semibold text-center md:text-left mb-6">Join Us Today!</h2>
                    <p className='md:text-base text-sm'>Ready to take your medical store to new heights? Join MediSeen and become a part of the future of pharmacy.</p>
                    <button type="submit" className="w-auto bg-blue-500 text-white py-2 px-4 rounded-md hover:bg-blue-600 transition duration-300 mt-4" onClick={checkAccountStatus}>Get Started</button>
                    <p className="text-sm pt-3">Have questions?<br />Contact our Partner Support Team at <span className="font-semibold">support@mediseen.online</span>.</p>
                </div>
                <div className="my-20 text-gray-500">
                    <h2 className="md:text-3xl text-lg font-semibold text-center mb-6">Frequently asked questions</h2>
                    <div className="md:w-4/6 w-4/5 mx-auto ">
                        <ul className="list-none">
                            {faqs.map((faq, index) => (
                            <li key={index} className="mb-2 bg-white px-6 rounded-sm">
                                <h3
                                className="text-xl font-semibold mb-2 py-5 cursor-pointer"
                                onClick={() => toggleAnswer(index)}
                                >
                                {faq.question}
                                </h3>
                                {selectedQuestion === index && (
                                <p className="text-gray-700 text-justify border-t-[1px] py-5">{faq.answer}</p>
                                )}
                            </li>
                            ))}
                        </ul>
                    </div>
                </div>
            </div>
            <div>
                <div className="md:w-4/6 w-4/5 mx-auto mt-12 mb-4 text-gray-500">
                    <img src={logoColor} className="w-32 mx-auto md:mx-0" alt="MediSeen"/>
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 md:gap-8 gap-4 my-8 text-center md:text-left text-sm">
                        <div>
                            <h3 className="font-semibold text-xl">Contact Us</h3>
                            <ul>
                                <li>180018001800</li>
                                <li>support@mediseen.online</li>
                            </ul>
                        </div>
                        <div>
                            <h3 className="font-semibold text-xl">Information</h3>
                            <ul>
                                <li>About Us</li>
                                <li>Blogs</li>
                                <li>Events</li>
                                <li>Gallery</li>
                            </ul>
                        </div>
                        <div>
                            <h3 className="font-semibold text-xl">Links</h3>
                            <ul>
                                <li className="hover:text-gray-600">Services</li>
                                <li className="hover:text-gray-600">Support</li>
                                <li className="hover:text-gray-600"><Link to="/polices/privacypolicy" target="_blank" rel="noopener noreferrer">Privacy Policy</Link></li>
                                <li className="hover:text-gray-600"><Link to="/polices/termsofservice" target="_blank" rel="noopener noreferrer">Terms of Service</Link></li>
                            </ul>
                        </div>
                        <div>
                            <h3 className="font-semibold text-xl">Social</h3>
                            <div className="flex flex-row md:flex-col justify-center md:justify-normal gap-2">
                                <div><FaFacebookF className="bg-gray-500 hover:bg-gray-600 cursor-pointer text-white rounded-full text-xl p-1" /></div>
                                <div><FaTwitter className="bg-gray-500 hover:bg-gray-600 cursor-pointer text-white rounded-full text-xl p-1" /></div>
                                <div><FaInstagram className="bg-gray-500 hover:bg-gray-600 cursor-pointer text-white rounded-full text-xl p-1" /></div>
                            </div>
                        </div>
                    </div>
                    <hr />
                    <div className="bg-white text-xs my-4">By continuing past this page, you agree to our Terms of Service, Cookie Policy, Privacy Policy and Content Policies. All trademarks are properties of their respective owners. 2023 © MediSeen. All rights reserved.</div>
                </div>
            </div>
            {/* Modal */}
            {isRegistrationModalOpen && (
                <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
                    <div className="bg-white p-4 rounded-md shadow-md md:w-2/6 w-5/6">
                        {step === 1 ? (
                        <div>
                            <div className="flex justify-between items-center mb-4">
                                <h2 className="text-xl font-semibold">Sign Up</h2>
                                <button type="button"  className="text-gray-600 hover:text-gray-800 transition duration-300" onClick={closeRegistrationModal}>
                                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                                    </svg>
                                </button>
                            </div>
                            <div>
                                <label className="block text-sm font-medium text-gray-600 pb-1">Full Name</label>
                                <input type="text" className={`w-full p-2 border rounded-md ${fullNameBorderColor}`} placeholder="eg: John Watt" value={fullName} onChange={(e) => setFullName(e.target.value)} onFocus={handleFullNameFocus}/>
                                <label className="block mt-3 text-sm font-medium text-gray-600 pb-1">Email</label>
                                <input type="email" className={`w-full p-2 border rounded-md ${emailBorderColor}`} placeholder="eg: example@xyz.com" value={email} onChange={(e) => setEmail(e.target.value)} onFocus={handleEmailFocus}/>
                                {emailExistError}
                                <div className="md:text-sm text-xs py-4">
                                    <input type="checkbox" className="text-red-600" checked={isChecked} onChange={handleCheckboxChange}/> I agree to MediSeen's <Link to="/polices/termsofservice" className="text-red-600" target="_blank" rel="noopener noreferrer">Terms of Service</Link>, <Link to="/polices/privacypolicy" className="text-red-600" target="_blank" rel="noopener noreferrer">Privacy Policy</Link> and <Link to="/polices/codeofconduct" className="text-red-600" target="_blank" rel="noopener noreferrer">Code of Conduct</Link>
                                </div>
                                <button type="button" className={`w-full text-white p-2 rounded-md hover:${buttonHoverBackgroundColor} transition duration-300 ${buttonBackgroundColor}`} onClick={handleCreateAccount} disabled={!isChecked}>Create Account</button>
                                {/* <p className="text-center font-medium text-gray-500 my-3">or</p> */}
                                {/* <button
                                    type="button"
                                    className="w-full bg-white border border-blue-500 text-blue-500 p-2 rounded-md hover:bg-blue-100 transition duration-300 mb-2"
                                    >
                                    <FcGoogle className="inline-block mr-2" />
                                    Continue with Google
                                </button>
                                <hr className="mx-2 my-4"/> */}
                                <p className="mt-2 text-sm">
                                    <span className="text-sm text-gray-500">Already have account? </span> 
                                    <span className="text-blue-500 hover:text-blue-600 cursor-pointer" onClick={openLoginModal}>Login</span>
                                </p>
                            </div>
                        </div>
                        ) : step === 2 ? (
                        <div>
                            <span className="cursor-pointer pb-6" onClick={changeState}><MdArrowBackIosNew className="text-gray-500 text-xl mt-2" /></span>
                            <div className="text-center font-bold text-xl m-4 mb-6 text-gray-600">Verification Code</div>
                            <div className="text-center m-4 text-gray-500 mb-8">
                                <p className="text-sm">Please enter the verification code sent to <span className="font-bold">{email}</span></p>
                            </div>
                            <div className="otpParent text-gray-500 pl-4 pr-4">
                                <OtpInput value={otp} onChange={setOtp} numInputs={6} inputStyle={'inputStyle'} shouldAutoFocus={true} inputType={'tel'} renderSeparator={''} renderInput={(props) => <input {...props} />} />
                            </div>
                            {otpInvalidError}
                            <div className="text-center mt-8 mb-2 text-gray-500 text-sm"><p>Didn't receive the code in{' '}<span className="font-semibold">{timerVisible ? `00:${remainingTime.toString().padStart(2, '0')}` : '00:00'}</span>?</p></div>
                            <div className="text-center mb-8 font-medium text-gray-500 text-sm">
                                {timerVisible ? (
                                    <span className="pr-2 pl-2 cursor-default">Resend</span>
                                    ) : (
                                    <span className="pr-2 pl-2 text-blue-500 cursor-pointer" onClick={handleResend}>
                                        Resend
                                    </span>
                                )}
                            </div>
                            <button type="button" className="w-full bg-blue-500 mb-4 text-white p-2 rounded-md hover:bg-blue-600 transition duration-300" onClick={handleVerifyOTP}>Verify Code</button>
                        </div>
                        ) : (
                        <div className="mt-4 mb-4">
                            <label className="block text-sm font-medium mb-1 text-gray-600">Password</label>
                            <div className="relative">
                                <input type={inputType} className="w-full p-2 mb-2 border border-gray-300 rounded-md pr-10" value={password} onChange={handlePasswordChange} />
                                <div className="absolute top-0 right-0 h-full flex items-center pr-2 cursor-pointer" onClick={togglePasswordVisibility} >
                                    {showPassword ? <BsEyeSlash /> : <BsEye />}
                                </div>
                            </div>
                            {/* <input type="password" className="w-full p-2 mb-2 border border-gray-300 rounded-md" value={password} onChange={handlePasswordChange} /> */}
                            <label className="block text-sm font-medium mb-1 text-gray-600">Confirm Password</label>
                            <div className="relative">
                                <input type={inputTypeCnf} className="w-full p-2 mb-2 border border-gray-300 rounded-md" value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} />
                                <div className="absolute top-0 right-0 h-full flex items-center pr-2 cursor-pointer" onClick={toggleCnfPasswordVisibility} >
                                    {showCnfPassword ? <BsEyeSlash /> : <BsEye />}
                                </div>
                            </div>
                            {/* <input type="password" className="w-full p-2 mb-2 border border-gray-300 rounded-md" value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} /> */}
                            <div>
                                {minCharState === 1 ? (
                                    <div className="text-gray-600">
                                    <span>
                                        <BsCircleFill className="inline-block mr-1 text-xsm" />
                                    </span>
                                    8 characters minimum
                                    </div>
                                ) : minCharState === 2 ? (
                                    <div className="text-red-600">
                                    <span>
                                        <BsX className="inline-block mr-1 text-lg" />
                                    </span>
                                    8 characters minimum
                                    </div>
                                ) : (
                                    <div className="text-green-600">
                                    <span>
                                        <BsCheck className="inline-block mr-1 text-lg" />
                                    </span>
                                    8 characters minimum
                                    </div>
                                )}

                                {oneUpCaseState === 1 ? (
                                    <div className="text-gray-600">
                                    <span>
                                        <BsCircleFill className="inline-block mr-1 text-xsm" />
                                    </span>
                                    At least one uppercase letter
                                    </div>
                                ) : oneUpCaseState === 2 ? (
                                    <div className="text-red-600">
                                    <span>
                                        <BsX className="inline-block mr-1 text-lg" />
                                    </span>
                                    At least one uppercase letter
                                    </div>
                                ) : (
                                    <div className="text-green-600">
                                    <span>
                                        <BsCheck className="inline-block mr-1 text-lg" />
                                    </span>
                                    At least one uppercase letter
                                    </div>
                                )}

                                {oneNumState === 1 ? (
                                    <div className="text-gray-600">
                                    <span>
                                        <BsCircleFill className="inline-block mr-1 text-xsm" />
                                    </span>
                                    At least one number
                                    </div>
                                ) : oneNumState === 2 ? (
                                    <div className="text-red-600">
                                    <span>
                                        <BsX className="inline-block mr-1 text-lg" />
                                    </span>
                                    At least one number
                                    </div>
                                ) : (
                                    <div className="text-green-600">
                                    <span>
                                        <BsCheck className="inline-block mr-1 text-lg" />
                                    </span>
                                    At least one number
                                    </div>
                                )}

                                {oneSpecSymbState === 1 ? (
                                    <div className="text-gray-600">
                                    <span>
                                        <BsCircleFill className="inline-block mr-1 text-xsm" />
                                    </span>
                                    At least one special symbol
                                    </div>
                                ) : oneSpecSymbState === 2 ? (
                                    <div className="text-red-600">
                                    <span>
                                        <BsX className="inline-block mr-1 text-lg" />
                                    </span>
                                    At least one special symbol
                                    </div>
                                ) : (
                                    <div className="text-green-600">
                                    <span>
                                        <BsCheck className="inline-block mr-1 text-lg" />
                                    </span>
                                    At least one special symbol
                                    </div>
                                )}
                                {isPasswordMismatch === true ? (
                                    <div className="text-red-600">
                                    <span>
                                        <BsX className="inline-block mr-1 text-lg" />
                                    </span>
                                    Password match failed
                                    </div>
                                ) : (
                                    <div className="hidden"></div>
                                )
                                }
                                </div>
                            <button type="button"
                            className="w-full bg-blue-500 text-white p-2 mt-4 rounded-md hover:bg-blue-600 transition duration-300" onClick={handleSetPassword}>Set Password</button>
                        </div>
                        )}
                    </div>
                </div>
            )}
            {isLoginModalOpen && (
                <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
                    <div className="bg-white p-4 rounded-md shadow-md w-80">
                        {loginStep === 1 ? (
                            <div>
                                <div className="flex justify-between items-center mb-4">
                                    <h2 className="text-xl font-semibold">Login</h2>
                                    {/* Close Modal */}
                                    <button type="button" className="text-gray-600 hover:text-gray-800 transition duration-300" onClick={closeLoginModal} >
                                        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" >
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                                        </svg>
                                    </button>
                                </div>
                                <div>
                                    <input type="email" className={`w-full p-2 border my-2 rounded-md ${loginEmailBorderColor}`} placeholder="Enter email" value={loginEmail} onChange={handleLoginEmailChange} onFocus={handleLoginEmailFocus}/>
                                    <div className="relative mt-2">
                                        <input type={inputTypeLogin} className="w-full p-2 mb-2 border border-gray-300 rounded-md" placeholder="Enter password" value={loginPassword}  onChange={handleLoginPasswordChange} />
                                        <div className="absolute top-0 right-0 h-full flex items-center pr-2 cursor-pointer" onClick={toggleLoginPasswordVisibility} >
                                            {showLoginPassword ? <BsEyeSlash /> : <BsEye />}
                                        </div>
                                    </div>
                                    {loginEmailExistError}
                                </div>
                                <button type="button" className="w-full bg-blue-500 text-white p-2 mt-2 rounded-md hover:bg-blue-600 transition duration-300" onClick={handleLogin} >Login</button>
                                <p className="mt-2 text-sm">
                                    <span className="text-sm text-gray-500">New to MediSeen? </span> 
                                    <span to="/registration" className="text-blue-500 hover:text-blue-600 cursor-pointer" onClick={openRegistrationModal}>Register Now</span>
                                </p>
                            </div>
                        ) : loginStep === 2 ? (
                            <div>
                                <span className="cursor-pointer pb-6" onClick={changeState}><MdArrowBackIosNew className="text-gray-500 text-xl mt-2" /></span>
                                <div className="text-center font-bold text-xl m-4 mb-6 text-gray-600">Verification Code</div>
                                <div className="text-center m-4 text-gray-500 mb-8">
                                    <p className="text-sm">Please enter the verification code sent to <span className="font-bold">{loginEmail}</span></p>
                                </div>
                                <div className="otpParent text-gray-500 pl-4 pr-4">
                                    <OtpInput value={otp} onChange={setOtp} numInputs={6} inputStyle={'inputStyle'} shouldAutoFocus={true} inputType={'tel'} renderSeparator={''} renderInput={(props) => <input {...props} />} />
                                </div>
                                {otpInvalidError}
                                <div className="text-center mt-8 mb-2 text-gray-500 text-sm"><p>Didn't receive the code in{' '}<span className="font-semibold">{timerVisible ? `00:${remainingTime.toString().padStart(2, '0')}` : '00:00'}</span>?</p></div>
                                <div className="text-center mb-8 font-medium text-gray-500 text-sm">
                                    {timerVisible ? (
                                        <span className="pr-2 pl-2 cursor-default">Resend</span>
                                    ) : (
                                        <span className="pr-2 pl-2 text-blue-500 cursor-pointer" onClick={handleResend}>
                                            Resend
                                        </span>
                                    )}
                                </div>
                                <button type="button" className="w-full bg-blue-500 mb-4 text-white p-2 rounded-md hover:bg-blue-600 transition duration-300" onClick={handleVerifyLoginOTP}>Verify Code</button>
                            </div>
                        ) : (
                            <div className="mt-4 mb-4">
                                <label className="block text-sm font-medium mb-1 text-gray-600">Password</label>
                                <div className="relative">
                                    <input type={inputType} className="w-full p-2 mb-2 border border-gray-300 rounded-md pr-10" value={password} onChange={handlePasswordChange} />
                                    <div className="absolute top-0 right-0 h-full flex items-center pr-2 cursor-pointer" onClick={togglePasswordVisibility} >
                                        {showPassword ? <BsEyeSlash /> : <BsEye />}
                                    </div>
                                </div>
                                <label className="block text-sm font-medium mb-1 text-gray-600">Confirm Password</label>
                                <div className="relative">
                                    <input type={inputTypeCnf} className="w-full p-2 mb-2 border border-gray-300 rounded-md" value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} />
                                    <div className="absolute top-0 right-0 h-full flex items-center pr-2 cursor-pointer" onClick={toggleCnfPasswordVisibility} >
                                        {showCnfPassword ? <BsEyeSlash /> : <BsEye />}
                                    </div>
                                </div>
                                <div>
                                    {minCharState === 1 ? (
                                        <div className="text-gray-600">
                                        <span>
                                            <BsCircleFill className="inline-block mr-1 text-xsm" />
                                        </span>
                                        8 characters minimum
                                        </div>
                                    ) : minCharState === 2 ? (
                                        <div className="text-red-600">
                                        <span>
                                            <BsX className="inline-block mr-1 text-lg" />
                                        </span>
                                        8 characters minimum
                                        </div>
                                    ) : (
                                        <div className="text-green-600">
                                        <span>
                                            <BsCheck className="inline-block mr-1 text-lg" />
                                        </span>
                                        8 characters minimum
                                        </div>
                                    )}
                                    {oneUpCaseState === 1 ? (
                                        <div className="text-gray-600">
                                        <span>
                                            <BsCircleFill className="inline-block mr-1 text-xsm" />
                                        </span>
                                        At least one uppercase letter
                                        </div>
                                    ) : oneUpCaseState === 2 ? (
                                        <div className="text-red-600">
                                        <span>
                                            <BsX className="inline-block mr-1 text-lg" />
                                        </span>
                                        At least one uppercase letter
                                        </div>
                                    ) : (
                                        <div className="text-green-600">
                                        <span>
                                            <BsCheck className="inline-block mr-1 text-lg" />
                                        </span>
                                        At least one uppercase letter
                                        </div>
                                    )}
                                    {oneNumState === 1 ? (
                                        <div className="text-gray-600">
                                        <span>
                                            <BsCircleFill className="inline-block mr-1 text-xsm" />
                                        </span>
                                        At least one number
                                        </div>
                                    ) : oneNumState === 2 ? (
                                        <div className="text-red-600">
                                        <span>
                                            <BsX className="inline-block mr-1 text-lg" />
                                        </span>
                                        At least one number
                                        </div>
                                    ) : (
                                        <div className="text-green-600">
                                        <span>
                                            <BsCheck className="inline-block mr-1 text-lg" />
                                        </span>
                                        At least one number
                                        </div>
                                    )}
                                    {oneSpecSymbState === 1 ? (
                                        <div className="text-gray-600">
                                        <span>
                                            <BsCircleFill className="inline-block mr-1 text-xsm" />
                                        </span>
                                        At least one special symbol
                                        </div>
                                    ) : oneSpecSymbState === 2 ? (
                                        <div className="text-red-600">
                                        <span>
                                            <BsX className="inline-block mr-1 text-lg" />
                                        </span>
                                        At least one special symbol
                                        </div>
                                    ) : (
                                        <div className="text-green-600">
                                        <span>
                                            <BsCheck className="inline-block mr-1 text-lg" />
                                        </span>
                                        At least one special symbol
                                        </div>
                                    )}
                                    {isPasswordMismatch === true ? (
                                        <div className="text-red-600">
                                        <span>
                                            <BsX className="inline-block mr-1 text-lg" />
                                        </span>
                                        Password match failed
                                        </div>
                                        ) : (
                                            <div className="hidden"></div>
                                        )
                                    }
                                </div>
                                <button type="button" className="w-full bg-blue-500 text-white p-2 mt-4 rounded-md hover:bg-blue-600 transition duration-300" onClick={handleSetLoginPassword}>Set Password</button>
                            </div>
                        )} 
                    </div>
                </div>
            )}
            {isPopUpOpen && (
                <div className={`fixed top-0 left-0 bg-gray-600/50 w-[100%] h-[100%] flex items-center justify-center z-50 modal open`}>
                <div className="modal-content bg-white rounded-md w-10/12 md:w-1/3">
                    <div className="p-4 pt-6 px-4 text-sm md:text-base md:px-8 flex text-center flex-col gap-4 max-h-[32rem] md:max-h-[28rem] overflow-hidden overflow-y-scroll md:overflow-auto md:overflow-y-auto">
                        <p className="text-green-500 text-lg font-semibold">Welcome Aboard!</p>
                        <p className="text-gray-700">Congratulations on successfully creating your account. We're thrilled to have you as part of our community.</p>
                        <p className="text-gray-700">To complete the registration process, please sign in and provide additional details.</p>
                        <div className="modal-footer flex pb-4 justify-center gap-2">
                            <button onClick={hidePopup} className="text-white bg-blue-500 border px-3 py-1 cursor-pointer hover:bg-blue-600 rounded-sm border-blue-500">OK</button>
                        </div>
                    </div>
                </div>
            </div>
            )}
            {notifyModal && (
                <div className={`fixed top-0 left-0 bg-gray-600/50 w-[100%] h-[100%] flex items-center justify-center z-50 modal open`}>
                    <div className="modal-content bg-white rounded-md w-10/12 md:w-1/3">
                        <div className="p-4 pt-6 px-4 text-sm md:text-base md:px-8 flex text-center flex-col gap-4 max-h-[32rem] md:max-h-[28rem] overflow-hidden overflow-y-scroll md:overflow-auto md:overflow-y-auto">
                            <p className="text-green-500 text-lg font-semibold">Hi there!</p>
                            <p className="text-gray-700">It looks like you haven't set up your store yet. Please complete the registration process to get started.</p>
                            <div className="modal-footer flex pb-4 justify-center gap-2">
                                <button onClick={hideNotifyModal} className="text-white bg-gray-500 border px-3 py-1 cursor-pointer hover:bg-gray-600 rounded-sm border-blue-500">Cancel</button>
                                <Link to="/partner-register" ><button className="text-white bg-blue-500 border px-3 py-1 cursor-pointer hover:bg-blue-600 rounded-sm border-blue-500">Register</button></Link>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            <ToastContainer />
        </div>
    );
};

export default Registration;
