import React, { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { FaUser, FaCog, FaBars, FaMaxcdn } from 'react-icons/fa'; // Import your desired icons FaHome
import {LiaChevronCircleLeftSolid} from 'react-icons/lia';
import { MdTextSnippet, MdNotificationsNone } from 'react-icons/md'; // MdDashboard, MdPeople
import PendingApprovals from './PendingApprovals';
import MedicineList from './MedicineList';

const Dashboard = () => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [showUserDropdown, setShowUserDropdown] = useState(false);
  const [showNotifications, setShowNotifications] = useState(false);

  const [showMobileMenu, setShowMobileMenu] = useState(false);
  const [activeComponent, setActiveComponent] = useState('applications');
  const notificationCount = 3;

  const [navbarHeight, setNavbarHeight] = useState(0);
  const navbarRef = useRef(null);

  useEffect(() => {
    // Calculate the height of the navbar dynamically
    if (navbarRef.current) {
      const height = navbarRef.current.offsetHeight;
      setNavbarHeight(height);
    }
  }, [navbarRef]);

  const Menus = [
    {title: "Applications", icon: <MdTextSnippet />, component: <PendingApprovals />},
    {title: "Medicine List", icon: <MdTextSnippet />, component: <MedicineList />},
    {title: "Settings", icon: <FaCog />}
  ]

  const handleLogout = () => {
    sessionStorage.removeItem('adminId');
    navigate('/admin-login');
  };

  return (
    <div className="">
      {/* Mobile Header */}
      <div className="md:hidden w-full bg-sky-900 p-4 flex justify-between items-center">
        <div className="text-white text-2xl">
          MediSeen
        </div>
        <FaBars
          className="text-white text-2xl cursor-pointer"
          onClick={() => setShowMobileMenu(!showMobileMenu)}
        />
      </div>
      <div className="flex h-screen">
        {/* Sidebar (For Desktop) */}
        <div className="hidden md:flex">
          {/* Sidebar */}
          <div className="flex">
            <div className={`${open ? "w-48" : "w-16"} h-screen p-3 pt-6 duration-200 bg-sky-900 relative`}>
                <LiaChevronCircleLeftSolid className={`absolute cursor-pointer rounded-full -right-3 top-14 bg-white text-2xl text-blue-500 ${!open && "rotate-180"}`} onClick = {() => setOpen(!open)} />
                <div className="flex gap-x-4 items-center">
                    <div className="p-2"><FaMaxcdn className={`text-white text-2xl cursor-pointer duration-500`} /></div>
                    <h1 className={`text-white origin-left font-medium text-xl duration-300 ${!open && "scale-0"}`}>MediSeen</h1>
                </div>
                <div className="pt-6">
                    {Menus.map((menu,index)=>(
                        <div key={index} className={`text-white text-sm flex items-center py-2 gap-x-4 cursor-pointer rounded-xl ${open && "hover:bg-white hover:text-[#0c4a6e]"}`} onClick={() => setActiveComponent(menu.title.toLowerCase())}>
                            <div className={`text-xl rounded-full ${!open && "hover:bg-white hover:text-[#0c4a6e]"} p-2 cursor-pointer duration-200`}>{menu.icon}</div>
                            <span className={`origin-left duration-200 ${!open && "scale-0"}`}>{menu.title}</span>
                        </div>
                    ))}
                </div>
            </div>
          </div>
        </div>

        {/* Sidebar (For Mobile) */}
        {showMobileMenu && (
          <div className="md:hidden bg-gray-600/60 absolute w-full h-screen top-0 right-0">

          </div>
        )}
        <div className={`md:hidden absolute top-0 right-0 h-screen ${showMobileMenu ? "w-72 p-4" : "w-0"} showMobileMenu duration-300 bg-blue-500`}>
          {showMobileMenu && (
            <div>
              <div className="flex py-2 gap-4 items-center">
                <div className="bg-white p-2 rounded-full">
                  <FaUser
                    className=" text-xl text-gray-500 cursor-pointer"
                    onClick={() => setShowUserDropdown(!showUserDropdown)}
                  />
                </div>
                <div className="text-white font-semibold">Admin</div>
              </div>
              <div className="flex flex-col">
                {Menus.map((menu, index) => (
                <div key={index} className={`text-white text-sm flex items-center py-2 gap-x-4 cursor-pointer rounded-xl ${!showMobileMenu && "scale-0"}`}
                  onClick={() => {
                    setActiveComponent(menu.title.toLowerCase());
                    setShowMobileMenu(false);
                  }}
                >
                  <div className={`text-xl rounded-full p-2 cursor-pointer ${!showMobileMenu && "scale-0"}`}>{menu.icon}</div>
                  <span>{menu.title}</span>
                </div>
                ))}
              </div>
            </div>
          )}
        </div>
        {/* Main Content Section */}
        <div className="w-full overflow-y-clip">
          {/* Top Navbar */}
          {/* Top Navbar (For Desktop) */}
          <div ref={navbarRef} className="hidden md:flex bg-white border-b-2 p-4 justify-end gap-x-4 items-center">
              {/* Store Name*/}
              <div className="text-gray-600 font-bold">Admin</div>
            {/* Notification Icon */}
            <div className="relative cursor-pointer">
              <MdNotificationsNone
                className="text-2xl text-gray-500"
                onClick={() => setShowNotifications(!showNotifications)}
              />
              {notificationCount > 0 && (
                <span className="absolute top-0 right-0 -mt-2 -mr-2 w-4 h-4 text-center bg-red-500 text-white text-xs rounded-full">
                  {notificationCount}
                </span>
              )}
            </div>

            {/* User Icon (with Dropdown) */}
            <div className="relative group">
              {showUserDropdown && (
                <div className="absolute right-0 mt-4 w-48 bg-white border rounded-lg shadow-lg">
                  {/* Add content for the user dropdown here, e.g., a Logout button */}
                  <button onClick={handleLogout} className="block w-full py-2 px-4 text-left hover:bg-gray-100">
                    Logout
                  </button>
                </div>
              )}
              <div className="p-2 bg-gray-200 rounded-full">
              <FaUser
                className=" text-xl text-gray-500 cursor-pointer"
                onClick={() => setShowUserDropdown(!showUserDropdown)}
              />
              </div>
            </div>
          </div>
          {/* Top Navbar (For Mobile) */}
          {/* Main Content */}
          <div className="flex-grow bg-white overflow-y-auto" style={{ height: `calc(100vh - ${navbarHeight}px)` }}>
            {/* Dashboard content goes here */}
            {Menus.find(menu => menu.title.toLowerCase() === activeComponent)?.component || 'Dashboard Content'}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
