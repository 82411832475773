import React from 'react';
import logoWhite from '../../img/logoNameWhite.png';

const CodeOfConduct = () => {
  return (
    <div className="bg-gray-100 min-h-screen">
        <header className="bg-blue-600 text-white h-12 md:h-16 flex flex-col justify-center px-4 md:px-8">
            <div className="flex items-center">
                <h1 className="md:text-2xl text-lg font-semibold mr-1 md:mr-2"><img src={logoWhite} alt="MediSeen" className="md:w-32 w-28"/></h1>
                <p className="md:text-sm text-xs text-gray-300 relative top-1">Partners</p>
            </div>
        </header>
        <div className="bg-gray-100 md:p-8 p-4">
            <div className="max-w-2xl md:text-base text-xs mx-auto bg-white p-4 md:p-6 text-gray-500 rounded shadow text-justify">
                <h1 className="md:text-3xl text-xl font-bold mb-4 md:mb-8">Code of Conduct</h1>
                <div className="font-semibold">Last Updated On Dec 01, 2023</div><br />
                <p>Welcome to MediSeen ("we," "us," or "our"). Our success and reputation are built on the trust of our users, partners, and the community. To maintain the highest standards of ethical behavior, we have established this Code of Conduct for all users and partners of our platform.</p><br />
                <h4 className="font-semibold text-sm md:text-lg">1. Respect and Professionalism</h4><br />
                <h5 className="font-semibold">1.1. Respect for Others:</h5><br />
                <ul>
                    <li>Treat all users, partners, and team members with respect, courtesy, and professionalism.</li>
                    <li>Avoid discriminatory language, behavior, or actions based on race, gender, religion, ethnicity, age, or any other factor.</li>
                </ul><br />
                <h5 className="font-semibold">1.2. Communication:</h5><br />
                <ul>
                    <li>Communicate openly, honestly, and respectfully with all stakeholders.</li>
                    <li>Refrain from engaging in any form of harassment, bullying, or disrespectful conduct.</li>
                </ul><br />
                <h4 className="font-semibold text-sm md:text-lg">2. Integrity and Honesty</h4><br />
                <h5 className="font-semibold">2.1. Accuracy of Information:</h5><br />
                <ul>
                    <li>Provide accurate and truthful information in all interactions with users and partners.</li>
                    <li>Do not engage in misinformation, deceit, or fraudulent activities.</li>
                </ul><br />
                <h5 className="font-semibold">2.2. Conflicts of Interest:</h5><br />
                <ul>
                    <li>Disclose any potential conflicts of interest that may compromise impartiality or objectivity.</li>
                    <li>Avoid situations where personal interests may conflict with the best interests of the community or the platform.</li>
                </ul><br />
                <h4 className="font-semibold text-sm md:text-lg">3. Compliance with Laws and Regulations</h4><br />
                <h5 className="font-semibold">3.1. Legal Compliance:</h5><br />
                <ul>
                    <li>Adhere to all applicable laws and regulations governing pharmaceuticals, online services, and user data protection.</li>
                    <li>Report any knowledge or suspicion of illegal activities on the platform promptly.</li>
                </ul><br />
                <h5 className="font-semibold">3.2. License and Certification:</h5><br />
                <ul>
                    <li>If you are a pharmacy partner, ensure that you maintain all necessary licenses and certifications required for your operations.</li>
                </ul><br />
                <h4 className="font-semibold text-sm md:text-lg">4. Privacy and Data Security</h4><br />
                <h5 className="font-semibold">4.1. User Privacy:</h5><br />
                <ul>
                    <li>Respect user privacy and adhere to our Privacy Policy in handling personal information.</li>
                    <li>Safeguard user data and report any breaches or vulnerabilities immediately.</li>
                </ul><br />
                <h5 className="font-semibold">4.2. Confidential Information:</h5><br />
                <ul>
                    <li>Protect confidential information, including trade secrets and proprietary data, and do not disclose such information without proper authorization.</li>
                </ul><br />
                <h4 className="font-semibold text-sm md:text-lg">5. Professionalism in Transactions</h4><br />
                <h5 className="font-semibold">5.1. Transparent Transactions:</h5><br />
                <ul>
                    <li>Conduct business transactions with transparency and integrity.</li>
                    <li>Provide clear and accurate information regarding products, pricing, and terms of service.</li>
                </ul><br />
                <h5 className="font-semibold">5.2. Quality of Products:</h5><br />
                <ul>
                    <li>Ensure the quality and safety of pharmaceutical products sold on the platform, complying with all applicable standards and regulations.</li>
                </ul><br />
                <h4 className="font-semibold text-sm md:text-lg">6. Reporting Violations</h4><br />
                <h5 className="font-semibold">6.1. Whistleblower Protection:</h5><br />
                <ul>
                    <li>Report any violations of this Code of Conduct promptly and without fear of retaliation.</li>
                    <li>Encourage a culture of accountability and ethical behavior.</li>
                </ul><br />
                <h4 className="font-semibold text-sm md:text-lg">7. Consequences of Violations</h4><br />
                <h5 className="font-semibold">7.1. Disciplinary Action:</h5><br />
                <ul>
                    <li>Violations of this Code of Conduct may result in disciplinary action, including suspension or termination of accounts and partnerships.</li>
                </ul><br />
                <h5 className="font-semibold">7.2. Legal Consequences:</h5><br />
                <ul>
                    <li>Serious violations may lead to legal consequences, including legal action and reporting to relevant authorities.</li>
                </ul><br />
                <h4 className="font-semibold text-sm md:text-lg">8. Continuous Improvement</h4><br />
                <h5 className="font-semibold">8.1. Feedback and Suggestions:</h5><br />
                <ul>
                    <li>Encourage open communication for feedback and suggestions to improve our platform and services.</li>
                    <li>Strive for continuous improvement in processes, policies, and user experience.</li>
                </ul><br />
                <p>By using our platform, you agree to abide by this Code of Conduct. We reserve the right to update or modify this Code of Conduct at any time. Please check this page regularly for updates.</p><br />
                <p>If you have any questions or concerns about this Code of Conduct, please contact us at <span className="font-semibold">support@mediseen.online</span>.</p><br />
                <p>Thank you for being part of MediSeen. Your commitment to ethical conduct contributes to the success and trustworthiness of our platform.</p>
            </div>
        </div>
    </div>
  );
};

export default CodeOfConduct;
