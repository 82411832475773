import React from 'react';
import logoWhite from '../../img/logoNameWhite.png';

const PrivacyPolicy = () => {
  return (
    <div className="bg-gray-100 min-h-screen">
        <header className="bg-blue-600 text-white h-12 md:h-16 flex flex-col justify-center px-4 md:px-8">
            <div className="flex items-center">
                <h1 className="md:text-2xl text-lg font-semibold mr-1 md:mr-2"><img src={logoWhite} alt="MediSeen" className="md:w-32 w-28"/></h1>
                <p className="md:text-sm text-xs text-gray-300 relative top-1">Partners</p>
            </div>
        </header>
        <div className="bg-gray-100 md:p-8 p-4">
            <div className="max-w-2xl md:text-base text-xs mx-auto bg-white p-4 md:p-6 text-gray-500 rounded shadow text-justify">
                <h1 className="md:text-3xl text-xl font-bold mb-4 md:mb-8">Privacy Policy</h1>
                <div className="font-semibold">Last Updated On Dec 01, 2023</div><br />
                <p>Welcome to MediSeen ("we," "us," or "our"). We are dedicated to protecting your privacy and ensuring the security of your personal information. This Privacy Policy outlines in detail how we collect, use, disclose, and protect your information when you use our website or mobile application (the "Platform").</p><br />
                <h4 className="font-semibold text-sm md:text-lg">1. Information We Collect</h4><br />
                <h5 className="font-semibold">1.1. Personal Information:</h5><br />
                <ul>
                    <li>When you register for an account, we collect a range of personal information to establish and manage your account. This includes your full name, email address, contact number, physical address, and other relevant details.</li>
                    <li>For pharmacy partners, we may collect additional information such as business registration details, license information, and relevant certifications.</li>
                </ul><br />
                <h5 className="font-semibold">1.2. Transaction Information:</h5><br />
                <ul>
                    <li>We collect and store information related to your transactions on the Platform. This includes purchase history, order details, and payment information. We do not store credit card information directly; instead, this is handled securely through our payment processing partners.</li>
                </ul><br />
                <h5 className="font-semibold">1.3. Device and Usage Information:</h5><br />
                <ul>
                    <li>We automatically gather information about the devices you use to access the Platform, including IP addresses, device type, browser type, and pages visited. This information helps us understand how you interact with the Platform and improve its performance and functionality.</li>
                </ul><br />
                <h5 className="font-semibold">1.4. Location Information:</h5><br />
                <ul>
                    <li>If you enable location services, we collect your device's precise location data. This information is used to provide localized services, including accurate delivery estimates.</li>
                </ul><br />
                <h5 className="font-semibold">1.5. Communication Preferences:</h5><br />
                <ul>
                    <li>We may collect information about your communication preferences, such as whether you wish to receive promotional emails or updates.</li>
                </ul><br />
                <h4 className="font-semibold text-sm md:text-lg">2. How We Use Your Information</h4><br />
                <h5 className="font-semibold">2.1. Service Provision:</h5><br />
                <ul>
                    <li>We use your information to provide, personalize, and improve our services, including order processing, inventory management, and delivery coordination.</li>
                </ul><br />
                <h5 className="font-semibold">2.2. Communication:</h5><br />
                <ul>
                    <li>We may use your contact information to send transactional emails, updates, and promotional materials.</li>
                </ul><br />
                <h5 className="font-semibold">2.3. Analytics:</h5><br />
                <ul>
                    <li>We analyze user behavior to enhance the functionality and user experience of the Platform.</li>
                </ul><br />
                <h4 className="font-semibold text-sm md:text-lg">3. Information Sharing and Disclosure</h4><br />
                <h5 className="font-semibold">3.1. Pharmacy Partners:</h5><br />
                <ul>
                    <li>We share necessary information with pharmacy partners to facilitate order processing and delivery.</li>
                </ul><br />
                <h5 className="font-semibold">3.2. Third-Party Service Providers:</h5><br />
                <ul>
                    <li>We may engage third-party service providers for functions such as payment processing and analytics.</li>
                </ul><br />
                <h5 className="font-semibold">3.3. Legal Compliance:</h5><br />
                <ul>
                    <li>We may disclose information to comply with applicable laws, regulations, or legal processes.</li>
                </ul><br />
                <h4 className="font-semibold text-sm md:text-lg">4. Your Choices</h4><br />
                <h5 className="font-semibold">4.1. Account Settings:</h5><br />
                <ul>
                    <li>You can manage your account settings to control the information you share with us.</li>
                </ul><br />
                <h5 className="font-semibold">4.2. Communication Preferences:</h5><br />
                <ul>
                    <li>You can opt-out of promotional communications via the settings in your account.</li>
                </ul><br />
                <h4 className="font-semibold text-base md:text-lg">5. Security</h4><br />
                <p>We implement security measures to protect your information. However, no method of transmission over the internet or electronic storage is completely secure, and we cannot guarantee absolute security.</p><br />
                <h4 className="font-semibold text-sm md:text-lg">6. Changes to this Privacy Policy</h4><br />
                <p>We may update this Privacy Policy periodically. Any changes will be effective immediately upon posting on the Platform.</p><br />
                <h4 className="font-semibold text-sm md:text-lg">7. Contact Us</h4><br />
                <p>If you have any questions or concerns about this Privacy Policy, please contact us at <span className="font-semibold">support@mediseen.online</span>.</p><br />
                <p>By using our Platform, you acknowledge that you have read, understood, and agreed to the practices described in this detailed Privacy Policy.</p>
            </div>
        </div>
    </div>
  );
};

export default PrivacyPolicy;
