// import './App.css';
// import { BrowserRouter, Routes, Route } from 'react-router-dom';
// import Login from './components/Login';
// import Registration from './components/Registration';
// import Dashboard from './components/Dashboard';

// function App() {
//   return (
//     <div className="App">
//       <BrowserRouter>
//         <Routes>
//             <Route path="/" element={<Login/>} />
//             <Route path="/registration" element={<Registration />} />
//             <Route path="/dashboard" element={<Dashboard/>} />
//         </Routes>
//       </BrowserRouter>
//     </div>
//   );
// }

// export default App;

import './App.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Registration from './components/Registration';
import Login from './components/Login';
import PrivacyPolicy from './components/policies/PrivacyPolicy';
import TermsOfService from './components/policies/TermsOfService';
import CodeOfConduct from './components/policies/CodeOfConduct';
import Dashboard from './components/Dashboard';
// import AuthGuard from './components/AuthGuard'; // Import the AuthGuard component
import RegisterPartner from './components/RegisterPartner';
import AdminLogin from './components/AdminLogin';
import AdminDashboard from './components/adminDashboard/Dashboard';

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          {/* Wrap the login and registration routes with AuthGuard */}
          <Route path="/" element={<Login />} />
          <Route path="/registration" element={<Registration />} />
          <Route path="/admin-login" element={<AdminLogin />} />
          <Route path="/admin-dashboard" element={<AdminDashboard />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/partner-register" element={<RegisterPartner />} />
          <Route path="/polices/privacypolicy" element={<PrivacyPolicy/>} />
          <Route path="/polices/termsofservice" element={<TermsOfService/>} />
          <Route path="/polices/codeofconduct" element={<CodeOfConduct/>} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;

