import React, { useState, useEffect } from 'react';
import axios from 'axios';

const PersonalSection = ({ personalData, setPersonalData, validationErrors }) => {
    const [fullName, setFullName] = useState(personalData.fullName || '');
    const [panNumber, setPanNumber] = useState(personalData.panNumber || '');
    const [gstin, setGstin] = useState(personalData.gstin || '');
    const [address, setAddress] = useState(personalData.address || '');
    const [state, setState] = useState(personalData.state || '');
    const [zipCode, setZipCode] = useState(personalData.zipCode || '');
    const [city, setCity] = useState(personalData.city || '');
    const [landmark, setLandmark] = useState(personalData.landmark || '');
    const [selectedFile, setSelectedFile] = useState(personalData.selectedFile || null);

    // Handle file input change

    useEffect(() => {
        fetchPartnerDetails();
    }, []);

    const fetchPartnerDetails = async () => {
        try {
            const user = JSON.parse(sessionStorage.getItem('user'));
            const response = await axios.post('https://partners.mediseen.online:3002/api/getPartnerDetails', {
              partnerId: user._id,
            });
            if (response.status === 200) 
            {
              const result = response.data[0];
	      if(typeof(result.ownerName) == "undefined"){
                  return;
              }
              setFullName(result.ownerName);
              setPanNumber(result.ownerPANNum);
              setGstin(result.gstin);
              setAddress(result.fullAddress);
              setState(result.state);
              setZipCode(result.zipCode);
              setCity(result.city);
              setLandmark(result.landmark);
              const url = result.ownerPANImg;
              const parsedUrl = new URL(url);
              const fileName = parsedUrl.pathname.split('/').pop();
              setSelectedFile({ name: fileName, url: result.ownerPANImg });

            }
          } catch (error) {
            console.error(error);
        }
    };

    const handleFullNameChange = (e) => {
        const name = e.target.value;
        setFullName(name);
    };

    const handlePanNumberChange = (e) => {
        const num = e.target.value;
        setPanNumber(num);
    };

    const handleGstinChange = (e) => {
        const gstinVal = e.target.value;
        setGstin(gstinVal);
    };

    const handleAddressChange = (e) => {
        const addressVal = e.target.value;
        setAddress(addressVal);
    };

    const handleStateChange = (e) => {
        const stateVal = e.target.value;
        setState(stateVal);
    };

    const handleZipCodeChange = (e) => {
        const zipcodeVal = e.target.value;
        setZipCode(zipcodeVal);
    };

    const handleCityChange = (e) => {
        const cityVal = e.target.value;
        setCity(cityVal);
    };

    const handleLandmarkChange = (e) => {
        const landmarkVal = e.target.value;
        setLandmark(landmarkVal);
    };

    const handleFileInputChange = (e) => {
        const file = e.target.files[0];
        setSelectedFile(file);
    };

    useEffect(() => {
        setPersonalData({
            fullName,
            panNumber,
            gstin,
            address,
            state,
            zipCode,
            city,
            landmark,
            selectedFile,
        });
    }, [fullName, panNumber, gstin, address, state, zipCode, city, landmark, selectedFile, setPersonalData]);
    
    return (
        <div className="personal-section text-left">
            <div className="pt-1">
                <label className="block text-sm text-start font-medium text-gray-600 pb-0">Full Name</label>
                <input type="text" id="fullName" className={`w-full h-10 p-2 border rounded-md ${
                        validationErrors.fullName ? 'error-border' : ''
                    } `} placeholder="Full Name" value={fullName}
                    onChange={handleFullNameChange} />
            </div>
            <div className="pt-1">
                <div>
                    <label className="block text-sm font-medium text-gray-600 pb-0">PAN Card(Number)</label>
                    <input type="text" id="panCardNum" className={`w-full h-10 p-2 border rounded-md ${
                        validationErrors.panNumber ? 'error-border' : ''
                    } `} placeholder="ABCDE1234F" value={panNumber}
                    onChange={handlePanNumberChange} />
                </div>
                <div className="pt-1">
                    <label className="block text-sm items-start font-medium text-gray-600 pb-0">PAN Card(Copy)</label>
                    <input type="file" id="drugLicenseScan" className="hidden" onChange={handleFileInputChange} />
                    <label htmlFor="drugLicenseScan" className={`py-2 block w-full border rounded-md cursor-pointer ${
                        validationErrors.selectedFile ? 'error-border' : ''
                    }`}><span className="p-1 ml-1 bg-blue-500 border rounded-md text-white text-center">Choose File</span>
                        <span className="pl-1 text-gray-400">{selectedFile ? selectedFile.name : 'No file chosen'}</span></label>
                </div>
            </div>
            <div className="pt-1">
                <label className="block text-sm text-start font-medium text-gray-600 pb-0">GSTIN</label>
                <input type="text" id="gstin" className={`w-full h-10 p-2 border rounded-md ${
                        validationErrors.gstin ? 'error-border' : ''
                    }`} placeholder="GSTIN" value={gstin}
                    onChange={handleGstinChange} />
            </div>
            <div className="pt-1">
                <label className="block text-sm text-start font-medium text-gray-600 pb-0">Address</label>
                <textarea rows="2" className={`w-full px-2 border rounded-md resize-none ${
                        validationErrors.address ? 'error-border' : ''
                    }`} value={address}
                    onChange={handleAddressChange}></textarea>
            </div>
            <div className="flex flex-col md:flex-row md:gap-x-2">
                <div className="pt-1 md:w-1/2">
                    <label className="block text-sm text-start font-medium text-gray-600 pb-0">State</label>
                    <select name="state" id="state" className={`w-full h-10 p-2 border rounded-md ${
                        validationErrors.state ? 'error-border' : ''
                    } `} value={state}
                    onChange={handleStateChange}>
                        <option value="">Select</option>
                        <option value="Andhra Pradesh">Andhra Pradesh</option>
                        <option value="Andaman and Nicobar Islands">Andaman and Nicobar Islands</option>
                        <option value="Arunachal Pradesh">Arunachal Pradesh</option>
                        <option value="Assam">Assam</option>
                        <option value="Bihar">Bihar</option>
                        <option value="Chandigarh">Chandigarh</option>
                        <option value="Chhattisgarh">Chhattisgarh</option>
                        <option value="Dadar and Nagar Haveli">Dadar and Nagar Haveli</option>
                        <option value="Daman and Diu">Daman and Diu</option>
                        <option value="Delhi">Delhi</option>
                        <option value="Lakshadweep">Lakshadweep</option>
                        <option value="Puducherry">Puducherry</option>
                        <option value="Goa">Goa</option>
                        <option value="Gujarat">Gujarat</option>
                        <option value="Haryana">Haryana</option>
                        <option value="Himachal Pradesh">Himachal Pradesh</option>
                        <option value="Jammu and Kashmir">Jammu and Kashmir</option>
                        <option value="Jharkhand">Jharkhand</option>
                        <option value="Karnataka">Karnataka</option>
                        <option value="Kerala">Kerala</option>
                        <option value="Madhya Pradesh">Madhya Pradesh</option>
                        <option value="Maharashtra">Maharashtra</option>
                        <option value="Manipur">Manipur</option>
                        <option value="Meghalaya">Meghalaya</option>
                        <option value="Mizoram">Mizoram</option>
                        <option value="Nagaland">Nagaland</option>
                        <option value="Odisha">Odisha</option>
                        <option value="Punjab">Punjab</option>
                        <option value="Rajasthan">Rajasthan</option>
                        <option value="Sikkim">Sikkim</option>
                        <option value="Tamil Nadu">Tamil Nadu</option>
                        <option value="Telangana">Telangana</option>
                        <option value="Tripura">Tripura</option>
                        <option value="Uttar Pradesh">Uttar Pradesh</option>
                        <option value="Uttarakhand">Uttarakhand</option>
                        <option value="West Bengal">West Bengal</option>
                    </select>
                </div>
                <div className="pt-1 md:w-1/2">
                    <label className="block text-sm text-start font-medium text-gray-600 pb-0">ZIP/Postal Code</label>
                    <input type="text" id="zipCode" className={`w-full h-10 p-2 border rounded-md ${
                        validationErrors.zipCode ? 'error-border' : ''
                    } `} placeholder="ZIP/Postal Code" value={zipCode}
                    onChange={handleZipCodeChange} />
                </div>
            </div>
            <div className="flex flex-col md:flex-row md:gap-x-2 pb-1.5">
                <div className="pt-1 md:w-1/2">
                    <label className="block text-sm text-start font-medium text-gray-600 pb-0">City</label>
                    <input type="text" id="city" className={`w-full h-10 p-2 border rounded-md ${
                        validationErrors.city ? 'error-border' : ''
                    } `} placeholder="City" value={city}
                    onChange={handleCityChange} />
                </div>
                <div className="pt-1 md:w-1/2">
                    <label className="block text-sm text-start font-medium text-gray-600 pb-0">Landmark</label>
                    <input type="text" id="landmark" className={`w-full h-10 p-2 border rounded-md `} placeholder="Landmark" value={landmark}
                    onChange={handleLandmarkChange} />
                </div>
            </div>
        </div>
    );
};

export default PersonalSection;
