import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Map, GoogleApiWrapper, Marker } from 'google-maps-react';

const StoreSection = ({ google, storeData, setStoreData, validationErrors }) => {
    const [storeName, setStoreName] = useState(storeData.storeName || '');
    const [drugLicNum, setDrugLicNum] = useState(storeData.drugLicNum || '');
    const [latitude, setLatitude] = useState(storeData.latitude || 0);
    const [longitude, setLongitude] = useState(storeData.longitude || 0);
    const [markerPosition, setMarkerPosition] = useState({ lat: latitude, lng: longitude });
    const [mapCenter, setMapCenter] = useState({ lat: latitude, lng: longitude });
    const [searchText, setSearchText] = useState(storeData.locationDescription || '');
    const [suggestions, setSuggestions] = useState([]);
    const [autocomplete, setAutocomplete] = useState(null);
    const [placesService, setPlacesService] = useState(null);
    const [selectedFile, setSelectedFile] = useState(storeData.selectedFile || null);

    useEffect(() => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition((position) => {
                const { latitude, longitude } = position.coords;
                setLatitude(latitude);
                setLongitude(longitude);
                setMarkerPosition({ lat: latitude, lng: longitude });
                setMapCenter({ lat: latitude, lng: longitude });
                setLocationDescription(latitude, longitude);
            });
        }
        setAutocomplete(new google.maps.places.AutocompleteService());
    }, []);

    useEffect(() => {
        setStoreData({
            storeName,
            drugLicNum,
            latitude,
            longitude,
            locationDescription: searchText,
            selectedFile,
        });
    }, [storeName, drugLicNum, latitude, longitude, searchText, selectedFile, setStoreData]);

    useEffect(() => {
        fetchPartnerDetails();
    }, []);

    const fetchPartnerDetails = async () => {
        try {
            const user = JSON.parse(sessionStorage.getItem('user'));
            const response = await axios.post('https://partners.mediseen.online:3002/api/getPartnerDetails', {
              partnerId: user._id,
            });
            if (response.status === 200) 
            {
	      if(typeof(response.data[0]) == "undefined"){
                return;
              }
              const result = response.data[0];
              setStoreName(result.storeName);
              setDrugLicNum(result.drugLicenseNumber);
              setLatitude(result.storeLatitude);
              setLongitude(result.storeLongitude);
              const url = result.drugLicenseImg;
              const parsedUrl = new URL(url);
              const fileName = parsedUrl.pathname.split('/').pop();
              setSelectedFile({ name: fileName, url: result.drugLicenseImg });

            }
          } catch (error) {
            console.error(error);
        }
    };
    const handleLocationChange = (mapProps, map, clickEvent) => {
        const lat = clickEvent.latLng.lat();
        const lng = clickEvent.latLng.lng();
        setLatitude(lat);
        setLongitude(lng);
        setMarkerPosition({ lat, lng });
        setMapCenter({ lat, lng });
    };

    const handleMarkerDragEnd = (mapProps, map, event) => {
        const lat = event.latLng.lat();
        const lng = event.latLng.lng();
        setLatitude(lat);
        setLongitude(lng);
        setMarkerPosition({ lat, lng });
        setLocationDescription(lat, lng);
    };
    
    

    const setLocationDescription = (lat, lng) => {
        const geocoder = new google.maps.Geocoder();
        const latlng = new google.maps.LatLng(lat, lng);
        geocoder.geocode({ location: latlng }, (results, status) => {
            if (status === 'OK' && results[0]) {
                setSearchText(results[0].formatted_address);
            }
        });
    };

    const handleSearchInputChange = (e) => {
        const text = e.target.value;
        setSearchText(text);
        if (autocomplete) {
            autocomplete.getPlacePredictions(
                {
                    input: text,
                    componentRestrictions: { country: 'in' }, // Adjust to your preferred country
                },
                (predictions) => {
                    setSuggestions(predictions);
                }
            );
        }
    };

    const handleStoreNameChange = (e) => {
        const name = e.target.value;
        setStoreName(name);
    };

    const handleDrugLicNumChange = (e) => {
        const num = e.target.value;
        setDrugLicNum(num);
    };

    const handleFileInputChange = (e) => {
        const file = e.target.files[0];
        setSelectedFile(file);
    };

    const handleSearchSuggestionClick = (suggestion) => {
        const selectedPlaceId = suggestion.place_id;
        if (placesService) {
            placesService.getDetails(
                {
                    placeId: selectedPlaceId,
                },
                (place, status) => {
                    if (status === 'OK') {
                        setLatitude(place.geometry.location.lat());
                        setLongitude(place.geometry.location.lng());
                        setMarkerPosition({
                            lat: place.geometry.location.lat(),
                            lng: place.geometry.location.lng(),
                        });
                        setMapCenter({
                            lat: place.geometry.location.lat(),
                            lng: place.geometry.location.lng(),
                        });
                        setSuggestions([]);
                        setSearchText(suggestion.description);
                    }
                }
            );
        }
    };

    return (
        <div className="store-section text-left">
            <div className="pt-1">
                <label className="block text-sm text-start font-medium text-gray-600">Store Name</label>
                <input
                    type="text"
                    id="storeName"
                    className={`w-full h-10 p-2 border rounded-md ${
                        validationErrors.storeName ? 'error-border' : ''
                    }`}
                    placeholder="Store Name"
                    value={storeName}
                    onChange={handleStoreNameChange}
                />
                {/* {validationErrors.storeName && ( // Display error message if storeName has an error
                    <div className="text-red-500">{validationErrors.storeName}</div>
                )} */}
            </div>
            <div className="pt-1">
                <div>
                    <label className="block text-sm font-medium text-gray-600">Drug License(Number)</label>
                    <input
                        type="text"
                        id="drugLicenseNum"
                        className={`w-full h-10 p-2 border rounded-md ${
                            validationErrors.drugLicNum ? 'error-border' : ''
                        }`}
                        placeholder="ABC123"
                        value={drugLicNum}
                        onChange={handleDrugLicNumChange}
                    />
                </div>
                <div className="pt-1">
                    <label className="block text-sm items-start font-medium text-gray-600">Drug License(Scan)</label>
                    <input type="file" id="drugLicenseScan" className="hidden" onChange={handleFileInputChange} />
                    <label htmlFor="drugLicenseScan" 
                        className={`py-2 w-full block border rounded-md cursor-pointer ${
                            validationErrors.selectedFile ? 'error-border' : ''
                        }`}
                    >
                        <span className="p-1 ml-1 bg-blue-500 border rounded-md text-white text-center">Choose File</span>
                        <span className="pl-1 text-gray-400">{selectedFile ? selectedFile.name : 'No file chosen'}</span>
                        
                        {/* {selectedFile && (
                            <span>
                                <a href={selectedFile.url} className="bg-green-500 mr-1 text-white p-1 border rounded-md text-center" target="_blank" rel="noopener noreferrer">
                                    View File
                                </a>
                            </span>
                        )} */}
                    </label>

                </div>
            </div>
            <div className="map-container pt-1 text-left">
                <label>Store Location:</label>
                <div className="flex gap-1 mb-2">
                    <input
                        type="text"
                        id="locationSearch"
                        className="w-full h-10 p-2 border rounded-md"
                        placeholder="Search for a location"
                        value={searchText}
                        onChange={handleSearchInputChange}
                    />
                    {/* <button className="bg-blue-500 text-white p-2 w-24 rounded-md" onClick={handleSearchSubmit}>
                        Search
                    </button> */}
                </div>
                {suggestions.length > 0 && (
                    <ul className="suggestions-dropdown absolute z-10 bg-white p-2 shadow rounded-lg">
                        {suggestions.map((suggestion) => (
                            <li
                                key={suggestion.place_id}
                                onClick={() => handleSearchSuggestionClick(suggestion)}
                                className="p-1 rounded-sm suggestion-item hover:text-white hover:bg-blue-500 hover:cursor-pointer"
                            >
                                {suggestion.description}
                            </li>
                        ))}
                    </ul>
                )}
                <Map
                    google={google}
                    containerStyle={{ width: '100%', height: '200px', position: 'relative', paddingBottom: '10px', boxShadow: '0 0 5px gray' }}
                    style={{ width: '100%', height: '200px', position: 'relative' }}
                    className="map"
                    center={mapCenter}
                    onClick={handleLocationChange}
                    onReady={(mapProps, map) => {
                        map.setOptions({
                            disableDefaultUI: true,
                            keyboardShortcuts: false,
                        });
                        setPlacesService(new google.maps.places.PlacesService(map));
                    }}
                >
                    <Marker
                        position={markerPosition}
                        draggable={true} // Make the marker draggable
                        onDragend={handleMarkerDragEnd} // Add the dragend event handler
                    />
                </Map>
            </div>
        </div>
    );
};

export default GoogleApiWrapper({
    apiKey: 'AIzaSyA4Vzs1VRiOO0Sc4MPFDwgRVcVdmfeJSqQ',
})(StoreSection);
