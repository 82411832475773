import React, { useState, useEffect } from 'react';
import Chart from 'chart.js/auto'; 
import doctorIllust1 from '../../img/doctor_illust1.png';
import { FaLongArrowAltUp, FaLongArrowAltDown } from "react-icons/fa";

const PartnerDashboard = ({ onCreateBill }) => {
    const [createBillClicked, setCreateBillClicked] = useState(false);
    const [listModalState, setListModalState] = useState({ open: false, type: "" });
    const [selectedOption, setSelectedOption] = useState('month');
    const [allOrderPercentComp, setAllOrderPercentComp] = useState(null);

    const handleCreateBillClick = () => {
        setCreateBillClicked(true);
        onCreateBill(); // Invoke the parent component's callback
    };

    const [inventoryInsights, setInventoryInsights] = useState({
        zeroOfflineStocks: [],
        zeroOnlineStocks: [],
        lowOfflineStocks: [],
        lowOnlineStocks: [],
        toBeExpired: [],
        expired: []
    });

    useEffect(() => {
        fetchInventoryInsights();
    }, []);
    
    useEffect(() => {
        fetchOrderInsights();
    }, [selectedOption]);

    const [orderInsights, setOrderInsights] = useState({
        allOrders: [],
        allOnlineOrders: [],
        allOfflineOrders: [],
        profit: 0
    });

    useEffect(() => {
        if(orderInsights.allOrders.length > 0){
            const data1 = Array(7).fill(0);
            const label1 = Array(7).fill(0);
            orderInsights.allOrders.forEach((order, index) => {
                data1[index] = order.data.length;
                label1[index] = sumTotalBillAmount(order.data);
            });
            renderLineGraph(data1, label1, 'allOrdersGraph');
        }
        if(orderInsights.allOnlineOrders.length > 0){
            const data1 = Array(7).fill(0);
            const label1 = Array(7).fill(0);
            orderInsights.allOnlineOrders.forEach((order, index) => {
                data1[index] = order.data.length;
                label1[index] = sumTotalBillAmount(order.data);
            });
            renderLineGraph(data1, label1, 'allOnlineOrdersGraph');
        }
        if(orderInsights.allOfflineOrders.length > 0){
            const data1 = Array(7).fill(0);
            const label1 = Array(7).fill(0);
            orderInsights.allOfflineOrders.forEach((order, index) => {
                data1[index] = order.data.length;
                label1[index] = sumTotalBillAmount(order.data);
            });
            renderLineGraph(data1, label1, 'allOfflineOrdersGraph');
        }
        // renderLineGraph(orderInsights.profit, 'profitGraph');
    }, [orderInsights]);

    const fetchInventoryInsights = async () => {
        const user = JSON.parse(sessionStorage.getItem('user'));
        try {
            const response = await fetch(`https://partners.mediseen.online:3002/api/inventory-insights?userId=${user._id}`);
            if (response.ok) {
                const data = await response.json();
                if (data && data.length > 0) { // Check if data is defined and not empty
                    setInventoryInsights(data[0]);
                } else {
                    console.error('Empty or invalid data received from the API');
                }
            } else {
                console.error('Failed to fetch inventory insights');
            }
        } catch (error) {
            console.error('Error fetching inventory insights:', error);
        }
    };  
    
    const fetchOrderInsights = async () => {
        const user = JSON.parse(sessionStorage.getItem('user'));
        try {
            const response = await fetch(`https://partners.mediseen.online:3002/api/order-insights?userId=${user._id}&duration=${selectedOption}`);
            if (response.ok) {
                const data = await response.json();
                if (data) {
                    setOrderInsights(data);
                } else {
                    console.error('Empty or invalid data received from the API');
                }
            } else {
                console.error('Failed to fetch order insights');
            }
        } catch (error) {
            console.error('Error fetching inventory insights:', error);
        }
    };
    
    useEffect(() => {
    }, [orderInsights]);
    
    const openList = (type) => {
        setListModalState({ open: true, type });
    };

    const closeList = () => {
        setListModalState({ open: false, type: "" });
    }

    const renderLineGraph = (data, label, canvasId) => {
        console.log(label);
        const canvas = document.getElementById(canvasId);
        if (!canvas) return;
      
        const existingChart = Chart.getChart(canvas);
        if (existingChart) {
            existingChart.destroy();
        }
        
        const ctx = canvas.getContext('2d');
        new Chart(ctx, {
            type: 'line',
            data: {
                labels: data,
                datasets: [
                {
                    data: data,
                    borderColor: 'red', // Color of the line
                    borderWidth: 2,
                    fill: false,
                    pointRadius: 0.5,
                    pointHoverRadius: 0,
                },
                ],
            },
            options: {
                responsive: true,
                maintainAspectRatio: false,
                layout: {
                    padding: {
                        left: 10,
                        right: 10,
                        top: 10,
                        bottom: 10,
                    },
                },
                scales: {
                    x: {
                      display: false,
                    },
                    y: {
                      display: false,
                    },
                },
                elements: {
                    line: {
                        tension: .5, // Increase line tension to 1
                    },
                },
                plugins: {
                    legend: {
                        display: false, // Hide legends
                    },
                },
            },
        });
    };   
    
    const sumTotalBillAmount = (data) => {
        let sum = 0;
        data.forEach(item => {
            if (item.totalBillAmount) {
                sum += parseFloat(item.totalBillAmount);
            }
        });
        return sum.toFixed(2);
    };

    useEffect(() => {
        if (orderInsights && orderInsights.allOrders.length > 0) {
            const sum = sumTotalBillAmount(orderInsights.allOrders[6].data);
            const sumOfLast = sumTotalBillAmount(orderInsights.allOrders[5].data);

            if(sumOfLast > 0)
            {
                const diff = sum - sumOfLast;
                const percent = parseFloat(diff / sumOfLast * 100);
                setAllOrderPercentComp(percent.toFixed(2));
            }
            else{
                setAllOrderPercentComp(null);
            }
        }
    }, [orderInsights]);

    return (
        <div className="verify-section p-8 bg-slate-100">
            <div className="flex md:flex-row flex-col text-center bg-blue-100 w-full rounded-md md:pb-0 pb-4 md:gap-6 items-center">
                <img src={doctorIllust1} className="w-24 p-2 mx-16" alt="" />
                <div className="p-3 pt-0 md:pt-3">
                    <p className="text-blue-500 font-bold text-2xl md:text-3xl">Never worry about your Inventory</p>
                </div>
                <div className="p-3">
                    <button className="bg-blue-500 text-white rounded-sm px-6 py-2 font-semibold text-lg md:hover:bg-blue-600" onClick={handleCreateBillClick}>Create Bill</button>
                </div>
            </div>
            <h3 className="pt-4 pb-0 font-semibold text-gray-500">Inventory Insights</h3>
            <div className="flex md:flex-row mt-4 flex-col gap-6 w-full items-center align-middle text-left text-gray-500">
                <div className="bg-white w-full flex flex-col justify-around md:w-3/12 min-h-[7rem] md:hover:scale-110 duration-300 text-red-500 md:hover:bg-red-500 md:hover:text-white rounded-md md:shadow-sm hover:shadow-md py-4 px-4 cursor-default">
                    <div className="text-sm">Out of Stocks</div>
                    <div className="flex justify-between">
                        <div className="font-semibold text-2xl">{inventoryInsights.zeroOfflineStocks.length}<span className="text-xs font-normal"></span></div>
                        {/* <div className="font-semibold text-2xl">{inventoryInsights.zeroOnlineStocks}<span className="text-xs font-normal">(Online)</span></div> */}
                    </div>
                    <div className="text-xs cursor-pointer underline md:no-underline md:hover:underline" onClick={() => openList('zeroOfflineStocks')}>See List</div>
                </div>
                <div className="bg-white w-full flex flex-col justify-around md:w-3/12 min-h-[7rem] md:hover:scale-110 duration-300 rounded-md shadow-sm md:hover:shadow-md py-4 px-4 cursor-default">
                    <div className="text-sm">Low Stocks</div>
                    <div className="font-semibold text-2xl">{inventoryInsights.lowOfflineStocks.length}</div>
                    {/* <div className="font-semibold text-2xl">{inventoryInsights.lowOnlineStocks}</div> */}
                    <div className="text-xs cursor-pointer underline md:no-underline md:hover:underline" onClick={() => openList('lowOfflineStocks')}>See List</div>
                </div>
                <div className="bg-white w-full flex flex-col justify-around md:w-3/12 min-h-[7rem] md:hover:scale-110 duration-300 rounded-md shadow-sm md:hover:shadow-md py-4 px-4 cursor-default">
                    <div className="text-sm">To be expired </div>
                    <div className="font-semibold text-2xl">{inventoryInsights.toBeExpired.length}</div>
                    <div className="text-xs cursor-pointer underline md:no-underline md:hover:underline" onClick={() => openList('toBeExpired')}>See List</div>
                </div>
                <div className="bg-white w-full flex flex-col justify-around md:w-3/12 min-h-[7rem] md:hover:scale-110 duration-300 rounded-md shadow-sm md:hover:shadow-md py-4 px-4 cursor-default">
                    <div className="text-sm">Item expired </div>
                    <div className="font-semibold text-2xl">{inventoryInsights.expired.length}</div>
                    <div className="text-xs cursor-pointer underline md:no-underline md:hover:underline" onClick={() => openList('expired')}>See List</div>
                </div>
            </div>
            <div className="pt-4 pb-0 flex justify-between">
                <h3 className="font-semibold text-gray-500">Order Insights</h3>
                <select className="border border-gray-300 rounded-md bg-white px-1 py-1 h-8 shadow-sm focus:outline-none" value={selectedOption} onChange={(e) => setSelectedOption(e.target.value)} >
                    <option value="day">Day</option>
                    <option value="week">Week</option>
                    <option value="month">Month</option>
                    <option value="quarter">Quarter</option>
                    <option value="halfyear">Half Year</option>
                    <option value="year">Year</option>
                    <option value="alltime">All Time</option>
                </select>
            </div>
            <div className="flex md:flex-row mt-4 flex-col gap-6 w-full items-center align-middle text-left text-gray-500">
                <div className="bg-white w-full flex flex-row justify-around md:w-3/12 min-h-[7rem] md:hover:scale-110 duration-300 rounded-md shadow-sm md:hover:shadow-md py-4 px-4 cursor-default">
                    <div className="w-7/12 flex flex-col">
                        <div className="text-sm">Orders so Far</div>
                        <div className="flex">
                            <div className="font-semibold text-2xl">{orderInsights.allOrders[6] ? (orderInsights.allOrders[6].data.length > 0 ? (sumTotalBillAmount(orderInsights.allOrders[6].data)) : ( 0 )) : ( 0 )}</div>
                            {allOrderPercentComp &&
                            <div className="flex items-end text-xs font-bold px-1">
                                <div className={`${allOrderPercentComp > 0 ? 'text-green-500' : 'text-red-500'}`}>{allOrderPercentComp}</div>
                                <div>{allOrderPercentComp > 0 ? <FaLongArrowAltUp className="text-green-500" /> : <FaLongArrowAltDown className="text-red-500" />}</div>
                            </div>
                            }
                        </div>
                        {allOrderPercentComp &&
                            <div className="text-xs">Compared to last {selectedOption}</div>
                        }
                    </div>
                    <div className="w-5/12">
                        <canvas id="allOrdersGraph" className="w-full h-full"></canvas>
                    </div>
                </div>
                <div className="bg-white w-full flex flex-row justify-around md:w-3/12 min-h-[7rem] md:hover:scale-110 duration-300 rounded-md shadow-sm md:hover:shadow-md py-4 px-4 cursor-default">
                    <div className="w-7/12 flex flex-col">
                        <div className="text-sm">Online Orders</div>
                        <div className="font-semibold text-2xl">{orderInsights.allOnlineOrders[6] ? (orderInsights.allOnlineOrders[6].data.length > 0 ? (sumTotalBillAmount(orderInsights.allOnlineOrders[6].data)) : ( 0 )) : ( 0 )}</div>
                        <div className="text-xs">Compared to last week</div>
                    </div>
                    <div className="w-5/12">
                        <canvas id="allOnlineOrdersGraph" className="w-full h-full"></canvas>
                    </div>
                </div>
                <div className="bg-white w-full flex flex-row justify-around md:w-3/12 min-h-[7rem] md:hover:scale-110 duration-300 rounded-md shadow-sm md:hover:shadow-md py-4 px-4 cursor-default">
                    <div className="w-7/12 flex flex-col">
                        <div className="text-sm">Offline Orders</div>
                        <div className="font-semibold text-2xl">{orderInsights.allOfflineOrders[6] ? (orderInsights.allOfflineOrders[6].data.length > 0 ? (sumTotalBillAmount(orderInsights.allOfflineOrders[6].data)) : ( 0 )) : ( 0 )}</div>
                        <div className="text-xs">Compared to last week</div>
                    </div>
                    <div className="w-5/12">
                        <canvas id="allOfflineOrdersGraph" className="w-full h-full"></canvas>
                    </div>
                </div>
                <div className="bg-white w-full flex flex-row justify-around md:w-3/12 min-h-[7rem] md:hover:scale-110 duration-300 rounded-md shadow-sm md:hover:shadow-md py-4 px-4 cursor-default">
                    <div className="w-7/12 flex flex-col">
                        <div className="text-sm">Profit</div>
                        <div className="font-semibold text-2xl">{orderInsights.profit}</div>
                        <div className="text-xs">Compared to last week</div>
                    </div>
                    <div className="w-5/12">
                        {/* <canvas id="profitGraph" className="w-full h-full"></canvas> */}
                    </div>
                </div>
            </div>
            <div>
                <div>
                    <div></div>
                </div>
            </div>
            {listModalState.open && (
                <div className={`fixed top-0 left-0 bg-gray-600/30 w-[100%] h-[100%] text-gray-500 flex items-center justify-center z-50 modal open`}>
                    <div className="modal-content bg-white rounded-md w-10/12 md:w-3/6">
                        <div className="px-4 flex flex-col gap-1 max-h-[32rem] md:max-h-[28rem] overflow-hidden overflow-y-scroll md:overflow-auto md:overflow-y-auto">
                            {listModalState.type === "zeroOfflineStocks" ? (
                                <div className="header pt-2">
                                    <h2 className="text-xl font-semibold">Out of Stocks</h2>
                                    <div className="py-2">
                                        <div className="flex gap-[1px] text-white">
                                            <div className="w-full p-1 bg-sky-800 rounded-sm shadow-sm">Name</div>
                                        </div>
                                    {inventoryInsights.zeroOfflineStocks.length > 0 ? (
                                        inventoryInsights.zeroOfflineStocks.map((item, index) => (
                                            <div key={index} className="flex gap-[1px] mt-1">
                                                <div className={`w-full p-1 rounded-sm ${index%2 == 1 ? "bg-blue-50" : "bg-slate-100"}`}>{item.medicineName}</div>
                                            </div>
                                        ))
                                    ) : (
                                        <div className="text-center p-2 bg-blue-50 mt-1 rounded-sm font-semibold">No data available</div>
                                    )}
                                    </div>
                                </div>
                            ) : listModalState.type === "lowOfflineStocks" ? (
                                <div className="header pt-2">
                                    <h2 className="text-xl font-semibold">Low Stocks</h2>
                                    <div className="py-2">
                                        <div className="flex gap-[1px] text-white">
                                            <div className="w-9/12 p-1 bg-sky-800 rounded-sm shadow-sm">Name</div>
                                            <div className="w-3/12 p-1 bg-sky-800 rounded-sm shadow-sm text-center">Units Available</div>
                                        </div>
                                    {inventoryInsights.lowOfflineStocks.length > 0 ? (
                                        inventoryInsights.lowOfflineStocks.map((item, index) => (
                                        <div key={index} className="flex gap-[1px] mt-1">
                                            <div className={`w-9/12 p-1 rounded-sm ${index%2 == 1 ? "bg-blue-50" : "bg-slate-100"}`}>{item.medicineName}</div>
                                            <div className={`w-3/12 p-1 rounded-sm text-center ${index%2 == 1 ? "bg-blue-50" : "bg-slate-100"}`}>{item.additionalData}</div>
                                        </div>
                                        )) 
                                    ): (
                                        <div className="text-center p-2 bg-blue-50 mt-1 rounded-sm font-semibold">No data available</div>
                                    )}
                                    </div>
                                </div>
                            ) : listModalState.type === "toBeExpired" ? (
                                <div className="header pt-2">
                                    <h2 className="text-xl font-semibold">Expiring Soon</h2>
                                    <div className="py-2">
                                        <div className="flex gap-[1px] text-white">
                                            <div className="w-9/12 p-1 bg-sky-800 rounded-sm shadow-sm">Name</div>
                                            <div className="w-3/12 p-1 bg-sky-800 rounded-sm shadow-sm text-center">Expiry Date</div>
                                        </div>
                                    {inventoryInsights.toBeExpired.length > 0 ? (
                                        inventoryInsights.toBeExpired.map((item, index) => (
                                        <div key={index} className="flex gap-[1px] mt-1">
                                            <div className={`w-9/12 p-1 rounded-sm ${index%2 == 1 ? "bg-blue-50" : "bg-slate-100"}`}>{item.medicineName}</div>
                                            <div className={`w-3/12 p-1 rounded-sm text-center ${index%2 == 1 ? "bg-blue-50" : "bg-slate-100"}`}>{item.additionalData.slice(0,7)}</div>
                                        </div>
                                        ))
                                    ) : (
                                        <div className="text-center p-2 bg-blue-50 mt-1 rounded-sm font-semibold">No data available</div>
                                    )}
                                    </div>
                                </div>
                            ) : (
                                <div className="header pt-2">
                                    <h2 className="text-xl font-semibold">Expired</h2>
                                    <div className="py-2">
                                        <div className="flex gap-[1px] text-white">
                                            <div className="w-9/12 p-1 bg-sky-800 rounded-sm shadow-sm">Name</div>
                                            <div className="w-3/12 p-1 bg-sky-800 rounded-sm shadow-sm text-center">Expiry Date</div>
                                        </div>
                                    {inventoryInsights.expired.length > 0 ? (
                                        inventoryInsights.expired.map((item, index) => (
                                        <div key={index} className="flex gap-[1px] mt-1">
                                            <div className={`w-9/12 p-1 rounded-sm ${index%2 == 1 ? "bg-blue-50" : "bg-slate-100"}`}>{item.medicineName}</div>
                                            <div className={`w-3/12 p-1 rounded-sm text-center ${index%2 == 1 ? "bg-blue-50" : "bg-slate-100"}`}>{item.additionalData.slice(0,7)}</div>
                                        </div>
                                        ))
                                    ) : (
                                        <div className="text-center p-2 bg-blue-50 mt-1 rounded-sm font-semibold">No data available</div>
                                    )}
                                    </div>
                                </div>
                            )}
                            <div className="modal-footer flex pb-4 justify-end gap-2">
                                <button onClick={closeList} className="text-red-500 bg-white border px-2 rounded-sm cursor-pointer hover:text-white hover:bg-red-500 py-1 border-red-500">Close</button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default PartnerDashboard;