import React, { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { FaHome, FaUser, FaCog, FaBars, FaMaxcdn } from 'react-icons/fa'; // Import your desired icons
import {LiaChevronCircleLeftSolid} from 'react-icons/lia';
import {MdDashboard, MdInventory, MdTextSnippet, MdDeliveryDining, MdNotificationsNone, MdPeople, MdLogout} from 'react-icons/md';
import { IoCreate } from "react-icons/io5";
import PartnerDashboard from './partnerDashboard/PartnerDashboard';
import Inventory from './partnerDashboard/Inventory';
import Personal from './partnerDashboard/Personal';
import Vendor from './partnerDashboard/Vendor';
import Bill from './partnerDashboard/Bill';
import BillRecord from './partnerDashboard/BillRecord';

const Dashboard = () => {
  const navigate = useNavigate();
  const [storeName, setStoreName] = useState('');
  const [isSmallScreen, setIsSmallScreen] = useState(false);

  useEffect(() => {
    const user = JSON.parse(sessionStorage.getItem('user'));
    const partnerDetails = JSON.parse(sessionStorage.getItem('partnerDetails'));
    setStoreName(partnerDetails.storeName);
    if (!user) {
      navigate('/');
    }
  }, [navigate]);

  useEffect(() => {
    const checkScreenSize = () => {
        setIsSmallScreen(window.innerWidth <= 768);
    };

    // Initial check and add event listener
    checkScreenSize();
    window.addEventListener('resize', checkScreenSize);

    // Cleanup the event listener on component unmount
    return () => {
        window.removeEventListener('resize', checkScreenSize);
    };
  }, []);
  
  const [open, setOpen] = useState(false);
  const [showUserDropdown, setShowUserDropdown] = useState(false);
  const [showNotifications, setShowNotifications] = useState(false);

  const [showMobileMenu, setShowMobileMenu] = useState(false);
  const [activeComponent, setActiveComponent] = useState('dashboard');
  const notificationCount = 3;

  const [navbarHeight, setNavbarHeight] = useState(0);
  const navbarRef = useRef(null);

  useEffect(() => {
    // Calculate the height of the navbar dynamically
    if (navbarRef.current) {
      const height = navbarRef.current.offsetHeight;
      setNavbarHeight(height);
    }
  }, [navbarRef]);

  const handleCreateBill = () => {
    setActiveComponent('bill');
  };

  const Menus = [
    {title: "Dashboard", icon: <MdDashboard />, component: <PartnerDashboard onCreateBill={handleCreateBill} />},
    {title: "Inventory", icon: <MdInventory />, component: <Inventory />},
    {title: "Vendors", icon: <MdPeople />, component: <Vendor />},
    {title: "Billing", icon: <IoCreate />, component: <Bill />},
    {title: "Billing History", icon: <MdTextSnippet />, component: <BillRecord />},
    // {title: "Delivery", icon: <MdDeliveryDining />},
    {title: "Profile", icon: <FaUser />, component: <Personal />},
    {title: "Settings", icon: <FaCog />}
  ]

  const handleLogout = () => {
    sessionStorage.removeItem('user');
    navigate('/');
  };

  const hideMobileMenu = () => {
    setShowMobileMenu(false);
  }
  return (
    <div className="">
      <div className="flex h-screen">
        { !isSmallScreen ? (
          <div className="hidden md:flex">
            <div className="flex">
              <div className={`${open ? "w-48" : "w-16"} h-screen p-3 pt-6 duration-200 bg-blue-500 relative`}>
                <LiaChevronCircleLeftSolid className={`absolute cursor-pointer rounded-full -right-3 top-14 bg-white text-2xl text-blue-500 ${!open && "rotate-180"}`} onClick = {() => setOpen(!open)} />
                <div className="flex gap-x-4 items-center">
                  <div className="p-2"><FaMaxcdn className={`text-white text-2xl cursor-pointer duration-500`} /></div>
                  <h1 className={`text-white origin-left font-medium text-xl duration-300 ${!open && "scale-0"}`}>MediSeen</h1>
                </div>
                <div className="pt-6">
                  {Menus.map((menu,index)=>(
                    <div key={index} className={`text-white text-sm flex items-center py-2 gap-x-4 cursor-pointer rounded-xl ${open && "hover:bg-white hover:text-blue-500"}`} onClick={() => setActiveComponent(menu.title.toLowerCase())}>
                      <div className={`text-xl rounded-full ${!open && "hover:bg-white hover:text-blue-500"} p-2 cursor-pointer duration-200`}>{menu.icon}</div>
                      <span className={`origin-left duration-200 ${!open && "scale-0"}`}>{menu.title}</span>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        ):(
          <div className={`md:hidden absolute top-0 right-0 h-screen ${showMobileMenu ? "w-72 p-4" : "w-0"} z-50 showMobileMenu duration-300 bg-blue-500`}>
            {showMobileMenu && (
              <div>
                <div className="flex py-2 gap-4 items-center">
                  <div className="bg-white p-2 rounded-full">
                    <FaUser className=" text-xl text-gray-500 cursor-pointer" onClick={() => setShowUserDropdown(!showUserDropdown)} />
                  </div>
                  <div className="text-white font-semibold">{storeName}</div>
                </div>
                <div className="flex flex-col">
                  {Menus.map((menu, index) => (
                    <div key={index} className={`text-white text-sm flex items-center py-2 gap-x-4 cursor-pointer rounded-xl ${!showMobileMenu && "scale-0"}`} onClick={() => {setActiveComponent(menu.title.toLowerCase()); setShowMobileMenu(false); }} >
                      <div className={`text-xl rounded-full p-2 cursor-pointer ${!showMobileMenu && "scale-0"}`}>{menu.icon}</div>
                      <span>{menu.title}</span>
                    </div>
                  ))}
                  <div className={`text-white text-sm flex items-center py-2 gap-x-4 cursor-pointer rounded-xl ${!showMobileMenu && "scale-0"}`} onClick={() => {handleLogout(); setShowMobileMenu(false); }} >
                    <div className={`text-xl rounded-full p-2 cursor-pointer ${!showMobileMenu && "scale-0"}`}><MdLogout /></div>
                    <span>Logout</span>
                  </div>
                </div>
              </div>
            )}
          </div>
        )}
        {showMobileMenu && (
          <div className="bg-gray-900 w-screen h-screen absolute z-20 opacity-30" onClick={hideMobileMenu}></div>
        )}
        <div className="w-full flex flex-col h-screen">
          { !isSmallScreen ? (
            // desktop navbar
            <div ref={navbarRef} className="hidden md:flex bg-white border-b-2 p-4 justify-end gap-x-4 items-center">
              <div className="text-gray-600 font-bold">{storeName}</div>
              <div className="relative cursor-pointer">
                <MdNotificationsNone className="text-2xl text-gray-500" onClick={() => setShowNotifications(!showNotifications)} />
                {notificationCount > 0 && (
                  <span className="absolute top-0 right-0 -mt-2 -mr-2 w-4 h-4 text-center bg-red-500 text-white text-xs rounded-full">
                    {notificationCount}
                  </span>
                )}
              </div>
              <div className="relative group">
                {showUserDropdown && (
                  <div className="absolute right-0 mt-4 w-48 bg-white border rounded-lg shadow-lg">
                    <button onClick={handleLogout} className="block w-full py-2 px-4 text-left hover:bg-gray-100">
                      Logout
                    </button>
                  </div>
                )}
                <div className="p-2 bg-gray-200 rounded-full">
                  <FaUser className=" text-xl text-gray-500 cursor-pointer" onClick={() => setShowUserDropdown(!showUserDropdown)} />
                </div>
              </div>
            </div>
          ):
          (
            // mobile navbar
            <div ref={navbarRef} className="md:hidden w-full bg-blue-500 p-4 flex justify-between items-center">
              <div className="text-white text-2xl">MediSeen</div>
              <FaBars className="text-white text-2xl cursor-pointer" onClick={() => setShowMobileMenu(!showMobileMenu)} />
            </div>
          )}
          <div className="flex-grow bg-white overflow-y-scroll">
            {activeComponent === 'bill' ? <Bill /> : Menus.find(menu => menu.title.toLowerCase() === activeComponent)?.component || 'Dashboard Content'}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;