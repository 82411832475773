import React from 'react';
import logoWhite from '../../img/logoNameWhite.png';

const TermsOfService = () => {
    return (
        <div className="bg-gray-100 min-h-screen">
            <header className="bg-blue-600 text-white h-12 md:h-16 flex flex-col justify-center px-4 md:px-8">
                <div className="flex items-center">
                    <h1 className="md:text-2xl text-lg font-semibold mr-1 md:mr-2"><img src={logoWhite} alt="MediSeen" className="md:w-32 w-28"/></h1>
                    <p className="md:text-sm text-xs text-gray-300 relative top-1">Partners</p>
                </div>
            </header>
            <div className="bg-gray-100 md:p-8 p-4">
                <div className="max-w-2xl md:text-base text-xs mx-auto bg-white p-4 md:p-6 text-gray-500 rounded shadow text-justify">
                    <h1 className="md:text-3xl text-xl font-bold mb-4 md:mb-8">Terms Of Service</h1>
                    <div className="font-semibold">Last Updated On Dec 01, 2023</div><br />
                    <h4 className="font-semibold">Welcome to MediSeen – Your Premier Online Pharmacy Platform</h4><br />
                    <p>Thank you for choosing MediSeen as your preferred destination for pharmacy services. We invite you to carefully review the following comprehensive Terms of Service, which outline the rules and regulations governing your use of our website and mobile application (collectively referred to as the "Platform"). Your engagement with our services is contingent upon your acceptance and compliance with these terms.</p><br />
                    <h5 className="font-semibold">1. Acceptance of Terms</h5><br />
                    <p>By accessing, browsing, or utilizing our Platform, you acknowledge, understand, and agree to abide by these Terms of Service. Should you disagree with any aspect of these terms, please refrain from using our services.</p><br />
                    <h5 className="font-semibold">2. Description of Services</h5><br />
                    <p>MediSeen offers an expansive range of services designed to empower pharmacies, including cutting-edge inventory management tools, customizable online storefronts, advanced order processing systems, and seamless delivery coordination. These services aim to streamline operations and enhance the online presence of our esteemed pharmacy partners.</p><br />
                    <h5 className="font-semibold">3. Registration and Account Security</h5><br />
                    <p>To unlock the full potential of our Platform, users are required to register for an account. During the registration process, users must provide accurate, current, and complete information. Account holders are responsible for maintaining the confidentiality of their login credentials and for all activities occurring under their account.</p><br />
                    <h5 className="font-semibold">4. Pharmacy Partnerships</h5><br />
                    <p>Pharmacy partners joining forces with MediSeen must commit to operating in full compliance with local laws and regulations governing pharmaceutical sales. MediSeen assumes no responsibility for legal infractions committed by its pharmacy partners.</p><br />
                    <h5 className="font-semibold">5. Inventory Management</h5><br />
                    <p>Pharmacy partners bear the responsibility of maintaining precise and up-to-date inventory information on the Platform. MediSeen disclaims any liability for discrepancies in inventory or product information.</p><br />
                    <h5 className="font-semibold">6. Order Processing and Delivery</h5><br />
                    <p>While MediSeen strives for the timely delivery of products, we cannot guarantee specific delivery times or product availability. Delivery timelines may be influenced by factors such as location, traffic, and unforeseen external circumstances.</p><br />
                    <h5 className="font-semibold">7. User Conduct</h5><br />
                    <p>Users are strictly prohibited from engaging in any unlawful activities on the Platform. Prohibited activities include, but are not limited to, the transmission of illegal, threatening, abusive, defamatory, or objectionable content.</p><br />
                    <h5 className="font-semibold">8. Intellectual Property</h5><br />
                    <p>All content and materials on the Platform, including text, graphics, logos, images, and software, are the exclusive property of MediSeen and are protected by intellectual property laws.</p><br />
                    <h5 className="font-semibold">9. Termination of Services</h5><br />
                    <p>MediSeen reserves the right to terminate or suspend services to any user or pharmacy partner at its discretion, without prior notice, for any reason, including a breach of these Terms of Service.</p><br />
                    <h5 className="font-semibold">10. Changes to Terms of Service</h5><br />
                    <p>MediSeen reserves the right to modify or update these Terms of Service at any time. Changes will be effective immediately upon posting to the Platform. Continued use of the Platform after any changes constitutes your acceptance of the revised terms.</p><br />
                    <p>By utilizing MediSeen's Platform, you affirm that you have carefully read, understood, and agreed to be bound by these extensive Terms of Service. For inquiries or concerns, please reach out to us at <span className="font-semibold">support@mediseen.online</span>. Thank you for being part of MediSeen.</p>
                </div>
            </div>
        </div>
    );
};

export default TermsOfService;