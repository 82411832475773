import React, { useState, useEffect } from 'react';

const Personal = () => {
    
    return (
        <div className="verify-section text-center flex items-center h-full">
            Personal
        </div>
    );
};

export default Personal;