import React, { useState, useEffect, useRef } from 'react';
import {FaEdit, FaTrash, FaCheck, FaTimes, FaExclamationTriangle} from 'react-icons/fa';
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import validator from 'validator';

const AddVendorModal = ({ isOpen, onClose, onAddVendor }) => {
    const [vendorName, setVendorName] = useState('');
    const [vendorAddress, setVendorAddress] = useState('');
    const [vendorEmail, setVendorEmail] = useState('');
    const [vendorMobilePrimary, setVendorMobPrimary] = useState('');
    const [vendorMobileSecondary, setVendorMobSecondary] = useState('');

    useEffect(() => {
        if (isOpen) {
          // Clear the modal's state when it's opened
          setVendorName('');
          setVendorAddress('');
          setVendorEmail('');
          setVendorMobPrimary('');
          setVendorMobSecondary('');
        }
    }, [isOpen]);

    const handleAddVendor = () => {
        const user = JSON.parse(sessionStorage.getItem('user'));
        const newVendor = {
            name: vendorName,
            address: vendorAddress,
            email: vendorEmail,
            mobilePrimary: vendorMobilePrimary,
            mobileSecondary: vendorMobileSecondary,
            partnerId: user._id
        };
        onAddVendor(newVendor);
    };
    return (
        <div className={`fixed top-0 left-0 bg-gray-600/30 w-[100%] h-[100%] flex items-center justify-center z-50 modal ${isOpen ? 'open' : 'hidden'}`}>
            <div className="modal-content bg-white rounded-md w-10/12 md:w-3/6">
                <div className="header p-4 pb-0">
                    <h2 className="text-2xl font-semibold">Add Vendor</h2>
                    <hr className="py-1" />
                </div>
                <div className="px-4 flex flex-col gap-2 max-h-[32rem] md:max-h-[28rem] overflow-hidden overflow-y-scroll md:overflow-auto md:overflow-y-auto">
                    <div className="flex flex-col md:flex-row">
                        <label className="md:w-3/12">Vendor Name:</label>
                        <input type="text" value={vendorName} className="border-[1px] shadow-sm rounded-sm md:w-9/12 p-1 focus:outline-none focus:border-blue-500" onChange={(e) => setVendorName(e.target.value)} />
                    </div>
                    <div className="flex flex-col md:flex-row">
                        <label className="md:w-3/12">Vendor Address:</label>
                        <textarea value={vendorAddress} className="border-[1px] shadow-sm rounded-sm md:w-9/12 p-1 resize-none focus:outline-none focus:border-blue-500" onChange={(e) => setVendorAddress(e.target.value)} />                
                    </div>
                    <div className="flex flex-col md:flex-row">
                        <label className="md:w-3/12">Email:</label>
                        <input type="email" value={vendorEmail} className="border-[1px] shadow-sm rounded-sm md:w-9/12 p-1 focus:outline-none focus:border-blue-500" onChange={(e) => setVendorEmail(e.target.value)} />
                    </div>
                    <div className="flex flex-col md:flex-row">
                        <label className="md:w-3/12">Contact Number:</label>
                        <input type="text" value={vendorMobilePrimary} className="border-[1px] shadow-sm rounded-sm md:w-9/12 p-1 focus:outline-none focus:border-blue-500" onChange={(e) => setVendorMobPrimary(e.target.value)} />
                    </div>
                    <div className="flex flex-col md:flex-row">
                        <label className="md:w-3/12">Secondary Number:</label>
                        <input type="text" value={vendorMobileSecondary} className="border-[1px] shadow-sm rounded-sm md:w-9/12 p-1 focus:outline-none focus:border-blue-500" onChange={(e) => setVendorMobSecondary(e.target.value)} />
                    </div>
                    <div className="modal-footer flex pt-2 pb-4 justify-end gap-2">
                        <button onClick={handleAddVendor} className="text-blue-500 bg-white border px-5 py-1 cursor-pointer hover:text-white hover:bg-blue-500 rounded-sm border-blue-500">Add</button>
                        <button onClick={onClose} className="text-gray-500 bg-white border px-2 rounded-sm cursor-pointer hover:text-white hover:bg-gray-500 py-1 border-gray-500">Cancel</button>
                    </div>
                </div>
            </div>
        </div>
    );
};
const VendorCard = ({ vendor, onEdit, onDelete }) => (
    <div className="bg-white mb-4 shadow-md rounded-lg p-4">
        <div className="font-semibold text-gray-500">Name:</div>
        <div className="text-lg text-gray-800">{vendor.name}</div>
        <div className="font-semibold text-gray-500">Address:</div>
        <div className="text-gray-800">{vendor.address ? vendor.address: '-'}</div>
        <div className="font-semibold text-gray-500">Email:</div>
        <div className="text-gray-800">{vendor.email ? vendor.email: '-'}</div>
        <div className="font-semibold text-gray-500">Mobile:</div>
        <div className="text-gray-800">{vendor.mobilePrimary ? vendor.mobilePrimary: '-'}</div>
        <div className="font-semibold text-gray-500">Alt Mobile:</div>
        <div className="text-gray-800">{vendor.mobileSecondary ? vendor.mobileSecondary: '-'}</div>
        <div className="w-full flex justify-end">
          <FaEdit className="text-gray-400 hover:cursor-pointer mx-2" onClick={() => onEdit(vendor._id)} />
          <FaTrash className="text-red-500 hover:cursor-pointer mx-2" onClick={() => onDelete(vendor._id)} />
        </div>
    </div>
);
const Vendor = () => {
    const [vendorData, setVendorData] = useState([]);
    const [isSmallScreen, setIsSmallScreen] = useState(false);
    const [isAddVendorModalOpen, setAddVendorModalOpen] = useState(false);
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const [editedData, setEditedData] = useState({});
    const [editRow, setEditRow] = useState(null);
    const [deleteConfirmation, setDeleteConfirmation] = useState({ open: false, entryId: null });
    
    const [pageLimit, setPageLimit] = useState(10);
    const [page, setPage] = useState(1);
    const [hasMoreData, setHasMoreData] = useState(true);
    const [loading, setLoading] = useState(false);
    const observer = useRef();

    const handleIntersection = (entries) => {
        const target = entries[0];
        if (target.isIntersecting && !loading && hasMoreData) {
            fetchVendorData();
        }
    };

    useEffect(() => {
        fetchVendorData();
        observer.current = new IntersectionObserver(handleIntersection, {
            root: null,
            rootMargin: '0px',
            threshold: 1.0,
        });
        // Attach the observer to the last item in the list
        const targetNode = document.getElementById('lastItem');
        if (targetNode) {
            observer.current.observe(targetNode);
        }
        // Clean up the observer when the component unmounts
        return () => {
            if (observer.current) {
            observer.current.disconnect();
            }
        };
    }, [vendorData]);

    // useEffect(() => {
    //     fetchVendorData();
    // }, []);
    const fetchVendorData = async () => {
        const user = JSON.parse(sessionStorage.getItem('user'));
        const userIdParam = `userId=${user._id}`;
        const pageParam = `&page=${page}`;
        const limitParam = `&limit=${pageLimit}`;
        try {
          const response = await fetch(`https://partners.mediseen.online:3002/api/vendor?${userIdParam}${pageParam}${limitParam}`);
          if (response.ok) {
            const data = await response.json();
            // setVendorData(data);
            setVendorData((prevData) => [...prevData, ...data]);
            if (data.length < pageLimit) {
                setHasMoreData(false);
            }
            setPage((prevPage) => prevPage + 1);
          } else {
            console.error('Failed to fetch vendors data');
          }
        } catch (error) {
          console.error('Error fetching vendors data:', error);
        }
    };
    const handleAddVendor = async (newVendor) => {
        try {
            if (!newVendor.name) {
                toast.error('Vendor name cannot be blank.', { position: 'top-right' });
                return;
            }
            if (newVendor.email && !validator.isEmail(newVendor.email)) {
                toast.error('Invalid email address.', { position: 'top-right' });
                return;
            }
            if (newVendor.mobilePrimary && !/^[789]\d{9}$/.test(newVendor.mobilePrimary)) {
                toast.error('Invalid Primary Number.', { position: 'top-right' });
                return;
            }
            if (newVendor.mobileSecondary && !/^[789]\d{9}$/.test(newVendor.mobileSecondary)) {
                toast.error('Invalid Alternate Number.', { position: 'top-right' });
                return;
            }
            const response = await axios.post('https://partners.mediseen.online:3002/api/create-vendor', {newVendor});
            if (response.status === 201) {
                fetchVendorData();
                toast.success('Vendor created successfully!', { position: 'top-right' });
                setAddVendorModalOpen(false);
            }
        } catch (error) {
          toast.error('An error occurred while creating the vendor.', { position: 'top-right' });
        }
    };
    const handleDeleteClick = (entryId) => {
        setDeleteConfirmation({ open: true, entryId });
    };
    const handleDeleteConfirmation = async () => {
        try {
            const response = await axios.delete(`https://partners.mediseen.online:3002/api/delete-vendor/${deleteConfirmation.entryId}`);
            if (response.status === 200) {
                fetchVendorData();
                setDeleteConfirmation({ open: false, entryId: null });
                toast.success('Vendor deleted successfully!', { position: 'top-right' });
                setEditedData({});
            }
        } catch (error) {
            toast.error('An error occurred while deleting the vendor.', { position: 'top-right' });
        }
    };
    const handleDeleteCancel = () => {
        setDeleteConfirmation({ open: false, entryId: null });
    };
    const handleEditClick = (rowId) => {
        const itemToEdit = vendorData.find(item => item._id === rowId);
        setEditedData(itemToEdit);
        setEditRow(rowId);
        setIsEditModalOpen(true);
    };
    const handleSaveClick = async () => {
        try {
            if (!editedData.name) {
                toast.error('Vendor name cannot be blank.', { position: 'top-right' });
                return;
            }
            if (editedData.email && !validator.isEmail(editedData.email)) {
                toast.error('Invalid email address.', { position: 'top-right' });
                return;
            }
            if (editedData.mobilePrimary && !/^[789]\d{9}$/.test(editedData.mobilePrimary)) {
                toast.error('Invalid Primary Number.', { position: 'top-right' });
                return;
            }
            if (editedData.mobileSecondary && !/^[789]\d{9}$/.test(editedData.mobileSecondary)) {
                toast.error('Invalid Alternate Number.', { position: 'top-right' });
                return;
            }
            const response = await axios.put(`https://partners.mediseen.online:3002/api/update-vendor/${editedData._id}`, editedData);
            if (response.status === 200) {
                const updatedData = vendorData.map(item => (item._id === editedData._id ? response.data : item));
                setVendorData(updatedData);
                setEditRow(null);
                setIsEditModalOpen(false);
                setEditedData({});
                toast.success('Vendor updated successfully!', { position: 'top-right' });
            }
        } catch (error) {
            toast.error('An error occurred while updating the vendor.', { position: 'top-right' });
        }
    }; 
    const handleCancelClick = () => {
        setEditRow(null);
        setEditedData({});
        setIsEditModalOpen(false);
    };
    useEffect(() => {
        const checkScreenSize = () => {
            setIsSmallScreen(window.innerWidth <= 768);
        };
        checkScreenSize();
        window.addEventListener('resize', checkScreenSize);
        return () => {
            window.removeEventListener('resize', checkScreenSize);
        };
    }, []);
      
    return (
        <div className="flex flex-col md:flex-row h-full text-gray-600">
            <div className="w-full p-4 md:py-8 md:px-6">
                <div className="mb-4">
                    <div className="flex flex-row justify-between items-center">
                        <h2 className="text-xl font-semibold">Vendors</h2>
                        <div className="border-2 rounded-md cursor-pointer px-2 py-1 text-blue-500 hover:text-white hover:bg-blue-500 duration-200" onClick={() => setAddVendorModalOpen(true)}>+ Add Vendor</div>
                    </div>
                </div>
                {isSmallScreen ? (
                <div className="w-full text-sm">
                    {vendorData.map((vendor, index) => (
                        <VendorCard
                        key={vendor._id}
                        vendor={vendor}
                        onEdit={() => handleEditClick(vendor._id)}
                        onDelete={() => handleDeleteClick(vendor._id)}
                        isEditing={editRow === vendor._id}
                        editedData={editedData}
                        handleSaveClick={() => handleSaveClick(vendor)}
                        handleCancelClick={handleCancelClick}
                        onFieldChange={(field, value) => setEditedData({ ...editedData, [field]: value })}
                        id={index === vendorData.length - 1 ? 'lastItem' : null}
                      />
                    ))}
                    {loading && <p>Loading...</p>}
                </div>
                ) : (
                    <div className="w-full text-sm">
                        <div className="bg-white p-2 mb-[.1rem] shadow-md rounded-md flex items-center font-semibold text-gray-500">
                            <div className="px-2">#</div>
                            <div className="w-full flex items-center">
                                <div className="w-2/12">Name</div>
                                <div className="w-4/12">Address</div>
                                <div className="w-2/12">Email</div>
                                <div className="w-3/12 flex items-center">
                                    <div className="w-1/2">Mobile</div>
                                    <div className="w-1/2">Alt Mobile</div>
                                </div>
                                <div className="w-1/12"></div>
                            </div>
                        </div>
                        {vendorData.map((item, index) => (
                            <div key={item._id} className="bg-white p-2 mb-[.1rem] shadow-md rounded-md flex items-center">
                                <div className="px-2">{index + 1}</div>
                                <div className="w-full flex items-center">
                                    {editRow === item._id ? (
                                        <React.Fragment>
                                            <div className="w-2/12">
                                                <input type="text" className="border-[1px] shadow-sm rounded-sm w-full p-1 focus:outline-none focus:border-blue-500" value={editedData.name} onChange={(e) => setEditedData({ ...editedData, name: e.target.value })} />
                                            </div>
                                            <div className="w-4/12">
                                                <input type="text" className="border-[1px] shadow-sm rounded-sm w-full p-1 focus:outline-none focus:border-blue-500" value={editedData.address} onChange={(e) => setEditedData({ ...editedData, address: e.target.value })} />
                                            </div>
                                            <div className="w-2/12">
                                                <input type="text" className="border-[1px] shadow-sm rounded-sm w-full p-1 focus:outline-none focus:border-blue-500" value={editedData.email} onChange={(e) => setEditedData({ ...editedData, email: e.target.value })} />
                                            </div>
                                            <div className="w-3/12 flex items-center">
                                                <input type="text" className="border-[1px] shadow-sm rounded-sm w-full p-1 focus:outline-none focus:border-blue-500" value={editedData.mobilePrimary} onChange={(e) => setEditedData({ ...editedData, mobilePrimary: e.target.value })} />
                                                <input type="text" className="border-[1px] shadow-sm rounded-sm w-full p-1 focus:outline-none focus:border-blue-500" value={editedData.mobileSecondary} onChange={(e) => setEditedData({ ...editedData, mobileSecondary: e.target.value })} />
                                            </div>
                                            {/* <div className="w-2/12">
                                            </div>
                                            <div className="w-1/12">
                                            </div> */}
                                        </React.Fragment>
                                    ) : (
                                        <React.Fragment>
                                            <div className="w-2/12">
                                                <input type="text" className="border-[1px] shadow-sm rounded-sm w-full p-1 focus:outline-none focus:border-blue-500" value={item.name} readOnly />
                                            </div>
                                            <div className="w-4/12">
                                                <input type="text" className="border-[1px] shadow-sm rounded-sm w-full p-1 focus:outline-none focus:border-blue-500" value={item.address} readOnly />
                                            </div>
                                            <div className="w-2/12">
                                                <input type="text" className="border-[1px] shadow-sm rounded-sm w-full p-1 focus:outline-none focus:border-blue-500" value={item.email} readOnly />
                                            </div>
                                            <div className="w-3/12 flex items-center">
                                                <input type="text" className="border-[1px] shadow-sm rounded-sm w-full p-1 focus:outline-none focus:border-blue-500" value={item.mobilePrimary} readOnly />
                                                <input type="text" className="border-[1px] shadow-sm rounded-sm w-full p-1 focus:outline-none focus:border-blue-500" value={item.mobileSecondary} readOnly />
                                            </div>
                                            {/* <div className="w-2/12">
                                                <input type="text" className="border-[1px] shadow-sm rounded-sm w-full p-1 focus:outline-none focus:border-blue-500" value={item.mobilePrimary} readOnly />
                                            </div>
                                            <div className="w-1/12">
                                                <input type="text" className="border-[1px] shadow-sm rounded-sm w-full p-1 focus:outline-none focus:border-blue-500" value={item.mobileSecondary} readOnly />
                                            </div> */}
                                        </React.Fragment>
                                    )}
                                    <div className="w-1/12 flex justify-around">
                                        {editRow === item._id ? (
                                            <React.Fragment>
                                                <FaCheck className="text-green-500 hover:cursor-pointer" onClick={() => handleSaveClick(item)} />
                                                <FaTimes className="text-red-500 hover:cursor-pointer" onClick={() => handleCancelClick()} />
                                            </React.Fragment>
                                        ) : (
                                            <React.Fragment>
                                                <FaEdit className="text-gray-400 hover:cursor-pointer" onClick={() => handleEditClick(item._id)} />
                                                <FaTrash className="text-red-500 hover:cursor-pointer" onClick={() => handleDeleteClick(item._id)} />
                                            </React.Fragment>
                                        )}
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                )}
                <AddVendorModal isOpen={isAddVendorModalOpen} onClose={() => setAddVendorModalOpen(false)} onAddVendor={handleAddVendor} />
            </div>
            <ToastContainer />
            {deleteConfirmation.open && (
                <div className={`fixed top-0 left-0 bg-gray-600/30 w-[100%] h-[100%] flex items-center justify-center z-50 modal open`}>
                    <div className="modal-content bg-white rounded-md w-10/12 md:w-2/5">
                        <div className="header p-4">
                            <h2 className="text-2xl font-semibold"><FaExclamationTriangle className="text-red-500 inline-block text-2xl mb-2 mr-2" />Delete Vendor</h2>
                            <hr className="" />
                        </div>
                        <div className="px-4 flex flex-col gap-4 max-h-[32rem] md:max-h-[28rem] overflow-hidden overflow-y-scroll md:overflow-auto md:overflow-y-auto">
                            <p className="text-gray-500">
                                Are you sure you want to delete this vendor?
                            </p>
                            <div className="modal-footer flex pb-4 justify-end gap-2">
                                <button onClick={handleDeleteConfirmation} className="text-red-500 bg-white border px-5 py-1 cursor-pointer hover:text-white hover:bg-red-500 rounded-sm border-red-500">Delete</button>
                                <button onClick={handleDeleteCancel} className="text-gray-500 bg-white border px-2 rounded-sm cursor-pointer hover:text-white hover:bg-gray-500 py-1 border-gray-500">Cancel</button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {isSmallScreen && isEditModalOpen && (
                <div className={`fixed top-0 left-0 bg-gray-600/30 w-[100%] h-[100%] flex items-center justify-center z-50 modal open`}>
                    <div className="modal-content bg-white rounded-md w-10/12 md:w-2/5">
                        <div className="header p-4">
                            <h2 className="text-2xl font-semibold">Edit Details</h2>
                            <hr className="" />
                        </div>
                        <div className="px-4">
                            <div className="pb-2">
                                <label className="font-semibold text-gray-500">Vendor Name</label>
                                <input type="text" className="border-[1px] shadow-sm rounded-sm w-full p-1 focus:outline-none focus:border-blue-500" value={editedData.name} onChange={(e) => setEditedData({ ...editedData, name: e.target.value })} />
                            </div>
                            <div className="pb-2">
                                <label className="font-semibold text-gray-500">Address</label>
                                <textarea className="border-[1px] shadow-sm rounded-sm w-full p-1 resize-none focus:outline-none focus:border-blue-500" value={editedData.address} onChange={(e) => setEditedData({ ...editedData, address: e.target.value })}></textarea>
                                {/* <input type="text" className="border-[1px] shadow-sm rounded-sm w-full p-1 focus:outline-none focus:border-blue-500" value={editedData.address} onChange={(e) => setEditedData({ ...editedData, address: e.target.value })} /> */}
                            </div>
                            <div className="pb-2">    
                                <label className="font-semibold text-gray-500">Email</label>
                                <input type="text" className="border-[1px] shadow-sm rounded-sm w-full p-1 focus:outline-none focus:border-blue-500" value={editedData.email} onChange={(e) => setEditedData({ ...editedData, email: e.target.value })} />
                            </div>
                            <div className="pb-2">    
                                <label className="font-semibold text-gray-500">Mobile</label>
                                <input type="text" className="border-[1px] shadow-sm rounded-sm w-full p-1 focus:outline-none focus:border-blue-500" value={editedData.mobilePrimary} onChange={(e) => setEditedData({ ...editedData, mobilePrimary: e.target.value })} />
                            </div>
                            <div className="pb-2">    
                                <label className="font-semibold text-gray-500">Alternate Mobile</label>
                                <input type="text" className="border-[1px] shadow-sm rounded-sm w-full p-1 focus:outline-none focus:border-blue-500" value={editedData.mobileSecondary} onChange={(e) => setEditedData({ ...editedData, mobileSecondary: e.target.value })} />
                            </div>    
                        </div>
                        <div className="flex justify-end p-4 gap-2">
                            <button className="border-2 rounded-md cursor-pointer px-2 py-1 bg-green-500 font-semibold text-white" onClick={() => handleSaveClick(editedData)} >Update</button>
                            <button className="border-2 rounded-md cursor-pointer px-2 py-1 bg-red-500 font-semibold text-white" onClick={() => handleCancelClick()} >Cancel</button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default Vendor;