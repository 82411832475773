import React from 'react';
import { Oval } from 'react-loader-spinner';
// import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';

const LoaderOverlay = ({ loading }) => {
    return (
        loading && (
            <div className="loader-overlay absolute top-0 left-0 w-full h-full flex justify-center items-center bg-gray-200/25">
                <div className="flex items-center justify-center w-24 h-24 shadow-lg rounded-md opacity-100">
                    <Oval visible={true} color="#00BFFF" secondaryColor="#83e0ff" height="60" width="60" ariaLabel="oval-loading" wrapperStyle={{}} wrapperClass="" />
                </div>
            </div>
        )
    );
};

export default LoaderOverlay;
